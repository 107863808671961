/* Start: Layout */
.timeEntry{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: stretch;
    .timeEntry_header-left{
        order: 0;
        flex: 1 0 33%;
    }
    .timeEntry_header-mid{
        order: 1;
        flex: 1 0 33%;
    }
    .timeEntry_header-right{
        order: 2;
        flex: 1 0 33%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .timeEntry_body{
        order: 3;
        flex: 1 0 100%;
        padding-top: 0.5rem;
        z-index: 1;
        position: relative;
        margin-bottom: 1rem;
    }
    .timeEntry_footer-left{
        flex-grow: 1;
        order: 4;
        display: flex;
        align-items: center;
    }
    .timeEntry_footer-right{
        flex-grow: 0;
        order: 5;
        padding:0.5rem 0;
    }
    @include xs {
        .timeEntry_header-left{
            order: 1;
            flex: 1 0 100%;
        }
        .timeEntry_header-mid{
            order: 0;
            flex: 1 0 100%;
        }
    }
    @include md {
        
    }
}

.timeEntry{
    .timeEntry__timeslot{
        width: 100%;
        .timeEntry__timeslot-slot{
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 0.5rem;
            .info{
                font-size: 0.8rem;
                margin: 0.2rem 0 0.2rem 0.2rem;
                color: #999;
            }
            .col-left{
                flex-grow: 1;
                flex: 1 0 70%;
                display: flex;
                flex-wrap: wrap;
                .col_1{
                    flex: 1 0 100%;
                    padding:0.5rem 0.5rem 0 0.5rem;
                    .form-control, .input-col{
                        width: 100%;
                    }
                }
                .col_2{
                    flex: 1 0 100%;
                    display: flex;
                    flex-wrap: wrap;
                    padding:0.5rem;
                    .col__1{
                        flex:0 0 20%;
                        padding-right: 0.5rem;
                        .input-col{
                            margin-bottom: 0.5rem;
                            width: 100%;
                            &:last-of-type{
                                margin: 0;
                            }
                        }
                    }
                    .col__2{
                        flex-grow: 1;
                        .col-description{
                            height: 100%;
                            .input-bg{
                                font-family: 'Roboto', sans-serif;
                                padding: 0.5rem;
                                background: #fff;
                                height: inherit
                            }
                        }
                    }
                }
                .col_3{
                    flex:1 0 100%;
                    .tag-col{
                        width: 100%;
                        padding: 0.5rem;
                        display: flex;
                        flex-wrap: nowrap;
                        align-items: flex-start;
                        .tag-col-label{
                            flex-grow: 0;
                            flex-shrink: 1;
                            color: #999;
                            font-weight: 700;
                        }
                        .tag-col-tags{
                            flex-grow: 0;
                            flex-shrink: 1;
                            padding:0 0.5rem;
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;
                            .tags{
                                padding: 0 0.5rem;
                                .tags-tag{
                                    font-size: 0.9rem;
                                    padding: 0.2rem 0.1rem 0.2rem 0.8rem ;
                                    background: #eee;
                                    margin:0 0.3rem 0.3rem 0;
                                    border-radius: 50px;
                                    display: inline-block;
                                    .btn-tags-delete{
                                        margin-left: 0.3rem;
                                        font-size: 1rem;
                                        color: #ddd;
                                    }
                                    &:hover{
                                        .btn-tags-delete{
                                            color: crimson;    
                                        }
                                    }
                                }
                            }
                            div{
                                order: 0;
                            }
                            .MuiFormControl-root{
                                min-width: 120px;
                                margin-right: 1rem;
                            }
                            .btn-tags-add{
                                order: 1;
                                flex-grow: 0;
                                height: 24px;
                                font-size: 0.75rem;
                            }
                        }
                    }
                }
            }
            .col-right{
                flex-grow: 0;
                width: $right-col-width;
                display: flex;
                align-content: space-between;
                align-items: center;
                padding: 0.5rem;
                border-left: 1px dotted #ddd;
                position: relative;
                .col-time{
                    order: 0;
                    flex-grow: 0;
                    flex-shrink: 1;
                    max-width: 110px;
                    padding: 0;
                    .input-col,input{
                        width: 100%;
                        font-size: 1rem;
                        display: block;
                        color: #333;
                        font-weight: normal;
                    }
                    .input-bg{
                        padding:0;
                        border: none;
                        .MuiFormControl-root{
                            position: static;
                        }
                        .MuiInput-root{
                            background: #eee;
                            padding: 0 0.5rem;
                            border-radius: 4px;
                        }
                        .MuiFormHelperText-root{
                            position: absolute;
                            bottom: 0.5rem;
                            padding:0;
                        }
                        .MuiInput-root.Mui-error{
                            border:1px solid red;
                        }
                    }
                    .info{
                        font-size: 0.7rem;
                        font-weight: normal;
                        color: #999;
                        line-height: normal;
                        display: block;
                    }
                }
                .col-action{
                    order: 1;
                    flex-grow: 1;
                    flex-shrink: 0;
                    text-align: right;
                    button{
                        margin-left: 0.5rem;
                    }
                }
            }
            &:last-of-type{
                margin-bottom: 0;
                .col-right{
                    padding-bottom: 100px;
                    .col-time{
                        .input-bg{
                            .MuiFormHelperText-root{
                                bottom: 70px;    
                            }
                        }
                    }
                }
            }
            .input-bg{
                padding-bottom: 0.2rem;
                background: transparent;
                border: 1px solid #ddd;
                .form-control{
                    width: 100%;
                }
                .MuiInput-underline{
                    margin: 0 !important;
                    &::before,&::after{
                        content: none;
                    }
                }
                .MuiInput-input{
                    color: $color-primary;
                    font-size: 0.9rem;
                }
                .Mui-focused{
                    background: transparent;
                    .MuiInput-input{
                        background: transparent;
                    }
                }
                .MuiFormLabel-root{
                    top: -1.3rem;
                }
                .MuiFormLabel-filled, label.Mui-focused{
                    top: -0.3rem;
                    text-transform: uppercase;
                    font-size: 0.8rem;
                    font-weight: 400;
                    color: #999;
                    & + .MuiInput-underline{
                        &::before,&::after{
                            content: none;
                        }
                    }
                }
                .MuiSelect-icon{
                    top: calc(50% - 18px);
                    fill:rgba(0,0,0,0.3);
                    display: none;
                }
                .MuiAutocomplete-inputRoot{
                    padding-right: 31px !important;
                }
                .MuiAutocomplete-popupIndicator{
                    display: none;
                }
                .MuiAutocomplete-clearIndicator{
                    margin-top: -5px;
                }
            }
            @include xstomd{
                .col-left{
                    .col_2{
                        padding-bottom: 0.5rem;
                        .col__1{
                            flex: 1 0 100%;
                            padding: 0;
                            .input-col:last-of-type{
                                margin-bottom: 0.5rem;
                            }
                            .ant-select{
                                margin-bottom: 0.5rem;
                            }
                        }
                        .col__2{
                            flex: 1 0 100%;
                        }
                    }
                    .col_3{
                        .tag-col{
                            flex-wrap: wrap;
                            .tag-col-label{
                                display: none;
                            }
                            .tag-col-tags{
                                padding: 0;
                                .tags{
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
                .col-right{
                    width: 100%;
                    border: none;
                    border-top: 1px dotted #ddd;
                    .col-time{
                        .input-bg{
                            .MuiInput-root.Mui-error{
                                margin-bottom: 1rem !important;
                            }
                            .MuiFormHelperText-root{
                                bottom: 5px;
                                margin: 0;
                            }
                        }
                    }
                }
                &:last-of-type{
                    .col-right{
                        padding-bottom: 60px;
                        .col-time{
                            .input-bg{
                                .MuiFormHelperText-root{
                                    bottom: 60px;
                                }
                            }
                        }
                    }
                }
            }
            &.slot_error{
                border: 1px solid red;
            }
            &.modal-slot{
                margin: 0 -12px;
                &:last-of-type{
                    .col-right{
                        padding-bottom: 0;
                        width: auto;
                        .col-time{
                            .input-bg{
                                .MuiFormHelperText-root{
                                    bottom: 0;    
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .timeEntry__slot-add{
        width: $right-col-width;
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 0.5rem 0.5rem 0.5rem 0 ;
        border-top: 1px dotted #ddd;
        .col-time{
            .error{
                font-weight: normal;
                font-size: 0.7rem;
            }
        }
        @include xs{
            width: 100%;
            padding: 0.5rem;
            background: #eee;
        }
    }
}
/* End: Layout */
.form-control-error{
    .input-bg,.ant-select-selection{
        border-color:red;
    }
}
.timeEntry{
    .timeEntry_header__title{
        flex-grow: 0;
        flex-shrink: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .timeEntry__title-title{
            margin: 0 0.5rem;
            font-weight: 700;
            font-size: 1rem;
        }
    }
    .timeEntry__date{
        padding:0.2rem 0;
        border-radius: 4px;
        box-shadow: 0 0 5px rgba(0,0,0,0.1);
        border: 1px solid $color_primary;
        .MuiFormControl-root{
            margin: 0;
        }
        .MuiInput-underline::before{
            content: none;
        }
        input{
            padding-left: 0.5rem;
            font-size: 0.9rem;
            color: $color_primary
        }
        .MuiIconButton-label{
            color: $color_primary;
        }
        @include xs{
            width: 100%;
            .MuiFormControl-root{
                width: 100%;
            }
        }
    }
    .timeEntry__inout{
        padding: 0.5rem;
        margin-left: 0.5rem;
        background: lighten($color-primary,45%);
        border-radius: 4px;
        width: $right-col-width;
    }
    .timeEntry__action{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .btn-summary{
            display: none;
        }
        button{
            margin-left: 0.5rem;
        }
        .MuiFormControlLabel-label{
            font-size: 0.8rem;
        }
        .keepValue-checkbox{
            padding: 4px;
        }
    }
    .col-time{
        padding:0 0.5rem;
        font-weight: 700;
        font-size: 1rem;
        line-height: normal;
        label{
            font-weight: 700;
            font-size: 0.7rem;
            display: block;
            text-transform: uppercase;
            color: #999;
        }
        .info{
            font-weight: normal;
            color: #666;
            font-size: 0.75rem;
            line-height: normal;
        }
        @include xs{
            padding:0;
        }
    }
}

.timesheet_list{
    &.xs-hidden{
        .timesheet_list-header{
            display: none;    
        }
        @include xs{
            opacity: 0;
            height: 0;
            position:relative;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 10000;
            background: #fff;
            transition: top 0.8s ease;
            overflow: hidden;
            .timesheet_list-header{
                position: fixed;
                top: 0;
                left: 0; right:0;
                z-index: 1;
                justify-content: space-between;
                align-items: center;
                height: $nav_height;
                background: #fff;
                box-shadow: 0 0 5px rgba(0,0,0,0.1);
                .title{
                    padding:0 0.5rem;
                    font-size: 1rem;
                    font-weight: 700;
                }
            }
            &.open{
                opacity: 1;
                position: absolute;
                top: 0;
                height: auto;
                padding-top: $nav_height;
                .timesheet_list-header{
                    display: flex;
                }
            } 
        }
    }
}
.timeEntry{
    @include xs{
        padding-bottom: $nav_height;
        .timeEntry_footer-right{
            flex: 1 0 100%;
            padding: 0;
            .timeEntry__action{
                justify-content: space-between;
                flex-wrap: wrap;
                position: relative;
                .keepValue-label{
                    position: absolute;
                    top: -30px;
                    right: 0;
                }
                .timeEntry__action-controls{
                    position: fixed;
                    bottom: 0;
                    left: 0; right: 0;
                    z-index: 1000;
                    height: $nav_height;
                    background-color: $color_grey;
                    display: flex;
                    justify-content: space-between;
                    align-items: stretch;
                    .btn-summary{
                        display: inline-block;
                        color: #fff;
                    }
                    .btn-cancel{
                        color: #fff;
                        border: none;
                    }
                    .btn-save{
                        border-radius: 0;
                        padding-left: 1rem;
                        padding-right: 1rem;
                    }
                    .btns{
                        display: flex;
                        align-items: stretch;
                        justify-content: space-between;
                        border-left: 1px solid rgba(255,255,255,0.2);
                    }
                    
                }
            }
            
        }
    }
    @include md{
        .timeEntry-header_left{
            order: 1;
        }
        .timeEntry-header_mid{
            order: 0;
            flex: 1 0 100%;
            position: relative;
        }
    }
    .timeEntry-header__date{
        @include xs {
            width: 50%;
            margin-right: 0.5rem;
            input{
                font-size: 0.8rem;
            } 
        }
    }
    .timeEntry-header__title-container{
        @include xs {
            .timeEntry-header__title_sub{
                text-align: left;
                line-height: normal;
            }
            .timeEntry-header__title{
                font-size: 0.8rem;
                line-height: normal;
            }
        }
        @include md{
            margin-bottom: 0.5rem;
            .timeEntry-header__title_sub{
                display: inline-block;
                font-size: 0.9rem;
                margin-right: 0.5rem;
            }
            .timeEntry-header__title{
                display: inline-block;
                font-size: 0.9rem;
            }
        }
    }
    
    .timeEntry_body{
        .timeEntry-body__slot-container{
            .timeEntry__slot{
                @include xs{
                    display: block;
                    .col-left{
                        .col-selectors{
                            display: block;
                            padding:0;
                            .form-control{
                                width: 100%;
                                label{
                                    top: -1.2rem;
                                }
                            }
                            .input-col{
                                padding: 0;
                                &::after{
                                    content: '';    
                                }
                            }
                            .input-bg{
                                border-radius: 0;
                                padding: 0.5rem 0.5rem 0rem 0.5rem;
                                background: rgba(0,0,0,0.05);
                                margin-bottom: 0.5rem; 
                                border-radius: 0.2rem;
                                .MuiInput-underline{
                                    margin-bottom: 0 !important;
                                    padding: 0;
                                    &::before,&::after{
                                        content: none;
                                    }
                                }
                                .MuiSelect-icon{
                                    display: block;
                                }
                                .MuiFormLabel-filled, label.Mui-focused{
                                    top: -0.3rem;
                                }
                                &:last-of-type{
                                    .MuiInput-underline{
                                        /*margin-bottom: 0rem !important;*/
                                    }
                                }
                            }
                        }
                        .col-description{
                            .input-bg{
                                padding: 0.5rem;
                            }
                        }
                    }
                    .col-mid{
                        padding: 0.5rem;
                        border-top: 1px dotted #ddd;
                        border-bottom: 1px dotted #ddd;
                        .tags_header{
                            padding: 0;
                            margin-bottom: 0.5rem;
                        }
                        .tags_wrapper{
                            padding: 0;
                            .tags-group{
                                width: calc(100% - 40px);
                            }
                            .tags-add{
                                .MuiIconButton-sizeSmall{
                                    font-size: 1.7rem;
                                    margin: 0;
                                }
                            }
                        }
                        .input-bg{
                            width: 100%;
                        }
                    }
                    .col-right{
                        padding: 0.5rem;
                        border-left: none;
                        width: 100%;
                        .col-action{
                            button{
                                padding: 0.6rem;
                            }    
                        }
                        .col-time{
                            max-width: 80px;
                        }
                    }
                }
            }
        }
    }
}
.tags_header{
    font-size: 0.9rem;
    padding: 0.2rem;
    color: #666
}
.tags_wrapper{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0.2rem;
    width: 100%;
    max-width: 420px;
    .input-bg{
        padding: 0;
        background: transparent;
    }
    .MuiInput-underline{
        margin: 0;
        &::before,&::after{
            content: '';
            border: none;
        }
    }
    .MuiInputLabel-formControl{
        font-size: 0.8rem;
        top: -0.8rem;
        &.MuiFormLabel-filled{
            display: none;
        }
    }
    .MuiAutocomplete-popupIndicator{
        display: none;
    }
    .tags-group{
        .input-col{
            margin-bottom: 0.5rem;
            background: #eee;
            padding:0 0 0 0.5rem;
            border-radius: 4px;
            &:last-of-type{
                margin-bottom: 0;
            }
        }
        .form-control{
            min-width: 160px;
            padding-right: 0.5rem;
            position: relative;
            &::after{
                content: '';
                width: 10px;
                height: 2px;
                background: #333;
                position: absolute;
                top: 50%;
                right: 0.5rem;
            }
            &:last-of-type{
                &::after{
                    content: none;
                }
            }
            .MuiInput-root{
                font-size: 0.8rem;
                padding-right: 31px;
            }
        }
    }
    .tags-add{
        margin:0 0 0.2rem 0;
        margin-left: 0.2rem;
    }
    @include xs{
        .tags-group{
            .form-control{
                min-width: 50%;
            }
        }
    }
}

.timesheet_list{
    .tab-navContainer{
        
    }
    .timeSheet-list_body{
        overflow-x:auto;
    }
}
.timesheet_list-summary{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .summary{
        flex-grow: 1;
    }
    .chart{
        flex-grow: 0;
        height: 100px;
        width: 100px;
        .chart-view{
            height: inherit !important;
        }
    }
    @include xs{
        
    }
}
.timeSheet-list_header{
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    .col-main{
        .payperiod{
            
        }
    }
    .col-action{
        width: 180px;//$right-col-width;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        border-left: 1px solid #ddd;
        .col-time{
            padding: 0.5rem;
            font-weight: 900;
            color: $color_primary;
            font-size: 1.2rem;
            line-height: normal;
            .info{
                font-weight: normal;
                display: block;
                font-size: 0.7em;
            }
        }
        .col-data{
            background: #eee;
            border-right: 1px solid rgba(0,0,0,0.05);
            border-left: 1px solid rgba(0,0,0,0.05);
            padding: 0.5rem 0.5rem 0.5rem 1rem;
            font-size: 0.75rem;
            max-width: 100px;
            font-size: 0.9rem;
            font-weight: 700;
            line-height: normal;
            position: relative;
            &::before{
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                width: 12px;
                height: 12px;
                margin: -6px 0 0 -6px;
                background: #fff;
                border-right: 1px solid rgba(0,0,0,0.15);
                border-top: 1px solid rgba(0,0,0,0.15);
                transform: rotate(45deg);
            }
            label{
                display: block;
                margin: 0;
                font-weight: normal;
                font-size: 0.8em;
                color: #666;
            }
            .value{
                
            }
        }
    }
    .col-item{
        padding:0.5rem;
        border-left: 1px solid #ddd;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        &:first-of-type{
            border: none;
            padding-left: 0.5rem;
        }
        label{
            flex: 1 0 100%;
            display: block;
            font-size: 0.9rem;
            color: #333;
            line-height: normal;
            margin: 0;
            font-weight:500;
        }
        .value{
            flex-shrink: 1;
            flex-grow: 0;
            line-height: normal;
            font-size: 0.9rem;
            display: block;
            color:#999;
            margin-right: 1rem;
            &:last-of-type{
                margin: 0;
            }
            .number{
                font-size:0.9rem;
                color:#333;
                font-weight:500;
            }
        }
        &.warning{
            background-color: lighten($color_warning,20%);
            label{
                color: #000;    
            }
        }
    }
    @include xs{
        flex-wrap: wrap;
        .col-main{
            align-items: center;
            flex-wrap: wrap;
            flex: 1 0 100%;
            .col-item{
                flex: 1 0 100%;
                border-left: none;
                border-bottom: 1px dotted #ddd;
                justify-content: flex-start;
                label{
                    flex: 1 0 30%;
                }
                .value{
                    flex: 1 0 70%;
                }
                &.d-felx_grow1{
                    
                }
            }
        }
        .col-action{
            width: 100%;
        }
    }
} 
.timeSheet-list_header-info{
    font-size: 0.75rem;
    text-align: center;
    .info,&.info,
    .warning,&.warning,
    .danger,&.danger,
    .success,&.success{
        padding: 0.2rem 0.5rem;
        display: block;
        width: 100%;
    }
    .info,&.info{
        background: #eee;
    }
    .warning,&.warning{
        background-color: lighten($color_warning,20%);
    }
    .danger,&.danger{
        background-color: lighten($color_danger,10%);
        color: #fff;
    }
    .success,&.success{
        background-color: $color_success;
        color: #fff;
    }
}
.timesheet-list_row-description{
    font-size:1em;
}
.timesheet-list_row-tags{
    font-size: 0.7rem;
    label{
        display: inline-block;
        color: #999;
        font-size: 0.8rem;
        margin-right: 0.5rem;
    }
    .tags,.tag{
        border-radius: 50px;
        background: $color_light_grey;
        display: inline-block; margin-right: 0.5rem;
        padding: 0.2rem 0.5rem;
    }
}
.timesheet-list_row-tasktype{
    font-size: 0.75rem;
    display: inline-block;
    background: #eee;
    border-radius: 50px;
    padding: 0.2rem 0.5rem;
    font-size: 0.6rem;
    text-transform: uppercase;
    margin-left: 0.5rem;
}
.timesheet-list_row-logtime{
    font-size: 0.7rem;
    color: #999;
    text-align: right;
}
.timesheet-list_row-time{
    &.col-time,.col-time{
        font-size: 0.9rem;
        padding: 0;
        font-weight: normal;
    }
}
.timesheet-list_row-date{
    color: $color_primary;
    font-size: 0.8rem;
    font-weight: 700;
}
.timesheet-list_row-daytotal,
.timesheet-list_row-weektotal,
.timesheet-list_col-timeinout-total{
    .timesheet-list_row-time{
        &.col-time,.col-time{
            font-weight: 700;
            color: $color_success;
        }
        .col-time{
            min-width: 40px;
            display: inline-block;
            .inline-icon{
                font-size: 1.2rem;
                vertical-align: top;
                color: rgba(0,0,0,0.4);
            }
        }
        .info{
            font-weight: 700;
            font-size: 0.75rem;
            color: $color_success;
        }
    }
    &.shaded{
        background-color: $color_light_grey !important;
        .timesheet-list_row-time{
            &.col-time,.col-time{
                color: #000;   
            }
            .info{
                color: #666;
                font-weight: normal;
            }
        }
    }
}
.timesheet-list_row-weektotal{
    &.shaded{
        background-color: darken($color_light_grey,18%) !important;
        .timesheet-list_row-time{
            &.col-time,.col-time{
                color: #345480;   
            }
            .info{
                color: #345480;   
            }
        }
    }
}
.timesheet-list_row-project-total{
    .info{
        font-style: italic;
        font-size: 0.8rem !important;
        color: #bc9756;
    }
    .col-time{
        color: #bc9756;
    }
}
.rowboarder-right{
    border-right: 1px solid #eee;
}
.timeEntry-summary_table{
    padding: 1rem;
    display: flex;
    .section-title{
        text-transform: uppercase;
        margin-bottom: 1rem;
        .sub{
            color: #666;
            font-weight: 100;
            padding: 0.5rem;
            background: #eee;
            border-radius: 4px;
            font-size: 0.8rem;
            margin-left: 1rem;
        }
        .summary-date{
            font-size: 0.8rem;
            color: #333;
        }
    }
    .summary-item{
        flex: 1 0 33%;
        padding:1rem;
    }
    .summary-list{
        margin: 0; padding: 0; list-style: none;
        li{
            display: flex;
            justify-content: space-between;
            border-bottom: 1px dotted #ddd;
            label{
                font-size: 0.8rem;
                flex-grow: 1;
                flex-shrink: 0;
                padding: 0.2rem;
            }
            .value{
                font-size: 0.8rem;
                flex-grow: 0;
                flex-shrink: 1;
                padding: 0.2rem;
            }
        }
    }
    table{
        border: 1px solid #ddd;
        border-collapse: collapse;
        font-size: 0.8rem;
        th,td{
            border: 1px solid #ddd;
            padding: 0.5rem;
            color: #666;
        }
        th{
            background: #eee;
        }
        td{
            text-align: right;
        }
        th.key{
            background: #fff;
            color: $color_primary;
        }
        th.head{
            text-transform: uppercase;
            font-size: 0.8rem;
        }
    }
}
.tasktype-badge{
    font-size: 0.65rem;
    background-color: #eee;
    color: #333;
    padding: 0.2rem 0.4rem;
    border-radius: 50px;
    margin:0 0.5rem;
    @include tasktype_colors;
}
.pto-adjustment-note{
    color:$color_primary;
    font-size: 0.90rem;
}
