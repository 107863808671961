.staff_tabel-detail-panel{
    background: #efefef;
    box-shadow:inset 0px 0px 10px rgba(0,0,0,.1);
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    .staff_tabel-detail-item{
        padding-right: 1rem;
        border-right: 1px dotted #ddd;
        padding: 1rem;
        label{
            font-weight: 500;
            display: block;
            line-height: normal;
            color: rgba(0,0,0,0.5);
            text-transform: uppercase;
            font-size: 0.8rem;
        }
        &:last-of-type{
            border: none;
        }
    }
}
.profile-view{
    font-size: 0.8rem !important;
}
.profile-view__left-header{
    width: 100%;
    padding-bottom: 1rem;
    display: flex;
    flex-wrap:nowrap;
    justify-content: space-between;
    align-items: stretch;
}
.profile-view__left-body{
    padding: 1rem 0;
}

.profile-view__basics{
    color: #222;
    flex-grow: 1;
    flex-shrink: 1;
    font-size: 1rem;
}
.porfile-view__avatar{
    width: 40px;
    height: 40px;
    margin-right: 0.5rem;
    flex-shrink: 1;
    flex-grow: 0;
    box-sizing: border-box;
}
.staff_avatar{
    background: $color_primary !important;
    font-weight: 700;
    font-size: 0.9rem !important; 
}
.profile-view__action-right{
    flex-grow: 0;
    flex-shrink: 1;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
}
.profile-view__basics-item{
    label{
        color: rgba(0,0,0,0.5);
    }
    &::before{
        color: rgba(0,0,0,0.5);
        margin-right: 0.3rem;
        font-size: 1rem;
    }
}
.profile-view__basics-item.item-title{
    margin: 0;
    font-size: 1em;
}
.profile-view__basics-item.item-designation{
    font-size: 0.7rem;
    text-transform: uppercase;
    color: #666;
    margin-bottom: 0.5rem;
}
.profile-view__basics-item.item-email{
    font-size: 0.8em;
}
.profile-view__basics-item.item-employeeid{
    font-size: 0.8em;
}
.profile-view__basics_default{}
.profile-view__basics_default-item{
    color: #222;
    margin: 0.5rem 0;
    line-height: normal;
    label{
        display: inline-block;
        color: rgba(0,0,0,0.5);
        line-height: normal;
        padding-right: 0.5rem;
        width: 25%;
        vertical-align: top;
        position: relative;
        &::after{
            content: ':';
            position: absolute;
            right: 0;
            top: 0.1rem;
        }
        
    }
    .value{
        width: 73%;
        padding-left: 0.5rem;
        display: inline-block;
    }
}
.profile-view__basics_default-block{
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 1rem;
    width: 100%;
    .profile-view__basics_default-item{
        padding: 0.5rem;
        flex-grow: 1;
        flex-shrink: 0;
        label{
            display: block;
            width: 100%;
            font-weight: 500;
            font-size: 0.7em;
            text-transform: uppercase;
            color: #666;
            &::after{
                content: none;
            }
            .sub{
                display: block;
                text-transform: none;
                color: #999
            }
        }
        .value{
            font-size: 1.1em;
            color: #222;
            padding: 0;
        }
    }
}
.profile-view__basics_card{
    background: rgba(0,0,0,0.025);
    padding: 1rem;
    margin-bottom: 1rem;
    .profile-view__basics_employment-item{
        label{
            width: 50%;
        }
    }
}
.profile-view__basics_cardwhite{
    padding:1rem 0;
}
.contact-group{
    margin-bottom: 30px;
    padding: 20px;
   .contact-group_head{
        display: flex;
        justify-content: space-between;
        margin:0 0 10px 0;
        .title{
            font-weight: 700;
            font-size: 1rem;
            .title-icon{
                vertical-align: middle;
                color: #999;
                margin-right: 5px;
            }
        }
    }
    .contact-list{
        margin: 0; padding: 0; list-style: none;
        li{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding:8px 10px;
            margin: 0 -10px;
            .contact-detils{
                flex: 1 0 70%;
                .contact-type{
                    font-size: 0.7rem;
                    color: #999;
                    text-transform: uppercase;
                    &.primary{
                        color:cadetblue
                    }
                }
                .contact-title{
                    color: #333;
                    font-size: 1rem;
                    .title-sub{
                        display: block;
                        font-size: 0.8rem;
                        color: #999;
                    }
                }
                .contact-content{
                    margin-top: 5px;
                    font-size: 0.8rem;
                    color: #666;
                    .contact-content_phone{
                        margin-right: 1rem;
                        font-size: 0.8rem;
                        &::before{
                            content: 'PHONE: ';
                            font-size: 0.7rem;
                            color: #999;
                        }
                    }
                    .contact-content_email{
                        font-size: 0.8rem;
                        &::before{
                            content: 'EMAIL: ';
                            font-size: 0.7rem;
                            color: #999;
                        }
                    }
                }
            }
            .contact-action{
                flex: 1 0 30%;
                text-align: right;
                opacity: 0;
            }
            &:hover{
                background: rgba(0,0,0,0.05);
                .contact-action{
                    opacity: 1;
                }
            }
        }
    }
}
.stepper-wrapper{
    flex: 1 0 80%;
}
