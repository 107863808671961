@import url("//fonts.googleapis.com/css?family=Roboto:400,500,700,900&display=swap");

$color_primary: #3f51b5;
$color_secondary: #f50057;
$color_grey: #5b626c;
$color_light_grey: lighten($color_grey, 52%);
$color_warning: #ffcc66;
$color_danger: $color_secondary;
$color_success: #05873c;

$color_primary_pastel: #bae1ff;
$color_secondary_pastel: #ffb4bb;
$color_warning_pastel: #f8e2a6;
$color_success_pastel: #b8d174;
$color_grey_pastel: #ffdfba;

@mixin stripebg($color_main, $color_sub) {
    background: repeating-linear-gradient(45deg, $color_sub, $color_sub 10px, $color_main 10px, $color_main 20px);
}
/* Tasktypes */
@mixin tasktype_colors {
    &.wh {
        background-color: $color_primary_pastel;
        color: #000;
        border: 1px solid transparent;
    }
    &.ho {
        background-color: $color_warning_pastel;
        color: #000;
        border: 1px solid transparent;
    }
    &.to {
        background-color: $color_secondary_pastel;
        color: #000;
        border: 1px solid transparent;
    }
    &.nb {
        background-color: $color_grey_pastel;
        color: #000;
        border: 1px solid transparent;
    }
    &.br {
        background-color: #fff;
        color: #666;
        border: 1px solid #ddd;
    }
}
/* Status colors */
@mixin status_colors {
    &.status_saved,
    &.saved,
    .status_saved,
    .saved {
        background-color: $color_primary_pastel;
        color: #000;
        border: 1px solid transparent;
    }
    &.status_submitted,
    &.submitted,
    .status_submitted,
    .submitted {
        background-color: $color_warning_pastel;
        color: #000;
        border: 1px solid transparent;
    }
    &.status_approved,
    &.approved,
    .status_approved,
    .approved {
        background-color: $color_success_pastel;
        color: #000;
        border: 1px solid transparent;
    }
    &.status_rejected,
    &.rejected,
    .status_rejected,
    .rejected {
        background-color: $color_secondary_pastel;
        color: #000;
        border: 1px solid transparent;
    }
    &.status_partially_approved,
    &.partially_approved,
    .status_partially_approved,
    .partially_approved {
        @include stripebg(lighten($color_success_pastel, 10%), lighten($color_success_pastel, 15%));
        color: #000;
        border: 1px solid transparent;
    }
    &.status_partially_rejected,
    &.partially_rejected,
    .status_partially_rejected,
    .partially_rejected {
        @include stripebg(lighten($color_secondary_pastel, 10%), lighten($color_secondary_pastel, 20%));
        color: #fff;
        border: 1px solid transparent;
    }
    &.status_holiday,
    &.holiday,
    .status_holiday,
    .holiday {
        background-color: #dde1d7;
        color: #003669;
        border: 1px solid transparent;
    }
}
.bg-highlight {
    background-color: $color_warning_pastel !important;
}
$nav_height: 50px;
$right-col-width: 240px; // important in timesheet page
$drawer-width: 350px;

$screen-sm-min: 576px; //landsacape
$screen-md-min: 768px; //protrait
$screen-lg-min: 992px; //tab&desktop
$screen-xl-min: 1200px; //large tab&desktop
@mixin xs {
    @media (max-width: #{$screen-md-min - 1}) {
        @content;
    }
}
@mixin sm {
    @media (min-width: #{$screen-sm-min}) and (max-width: #{$screen-md-min}) {
        @content;
    }
}
@mixin md {
    @media (min-width: #{$screen-md-min}) and (max-width: #{$screen-lg-min - 1}) {
        @content;
    }
}
@mixin xstomd {
    @media (max-width: #{$screen-lg-min - 1}) {
        @content;
    }
}
@mixin lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
}
@mixin xl {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
}
// Custom devices
@mixin rwd($screen) {
    @media (min-width: $screen+"px") {
        @content;
    }
}
/*
@include rwd(1400) {
    usage of custom breakpoints
}
*/

.statusSqr {
    width: 12px;
    height: 12px;
    background: #eee;
    display: inline-block;
    vertical-align: middle;
    @include status_colors;
}

.status-circle {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 21px;
    &::before{
        content: '';
        width:16px;
        height: 16px;
        display: block;
        background-color:#ddd;
        border-radius: 50%;
        position: absolute;
        left: 0;
    }
    &::after{
        content: '';
        width:16px;
        height: 16px;
        display: block;
        background-color:transparent;
        border-radius: 50%;
        position: absolute;
        left:0;
    }
    &.no-status{
        &::before,
        &::after{
            background-color: transparent;
        }
    }
    &.saved::after{
        background-color:$color_primary_pastel;;
    }
    &.submitted::after{
        background-color:$color_warning_pastel;;
    }
    &.approved::after{
        background-color:$color_success_pastel;
    }
    &.rejected::after{
        background-color:$color_secondary_pastel;
    }
    &.partially_approved::after{
        background-color:$color_success_pastel;
        border-top-left-radius:32px;
        border-bottom-left-radius:32px;
        width:8px;
    }
    &.partially_rejected::after{
        background-color:$color_secondary_pastel;
        border-top-left-radius:32px;
        border-bottom-left-radius:32px;
        width:8px;
    }
}

body {
    font-family: "Roboto", sans-serif;
    background-color: #fff !important;
}
.main-container {
    padding: 1rem;
    min-height: calc(100vh - 64px);
    display: block;
    overflow: hidden;
}
.appBarMain__toolbar {
    justify-content: space-between;
    align-items: center;
    display: flex;
    @include xs {
        background-color: $color_primary;
        button {
            color: #fff;
        }
        .branding {
            flex-grow: 1;
            .appBarMain__title {
                padding: 0;
            }
        }
        .appBarMain__title {
            padding: 0 1rem;
            color: #fff;
        }
    }
    @include md {
        background-color: $color_primary;
        button {
            color: #fff;
        }
        .branding {
            flex-grow: 1;
            .appBarMain__title {
                padding: 0;
            }
        }
        .appBarMain__title {
            padding: 0 1rem;
            color: #fff;
        }
    }
}
.appbarMain__toolbar-navmain {
    display: flex;
    flex-grow: 1;
    align-items: center;
    .subnav {
        flex-grow: 1;
        margin-left: 0.5rem;
        .subnav-item {
            text-transform: none;
            &.active {
                color: $color_primary;
                &::after {
                    content: "";
                    height: 4px;
                    width: 100%;
                    background: $color_primary;
                    position: absolute;
                    bottom: -15px;
                }
            }
        }
    }
    @include xs {
        width: 250px;
        align-items: flex-start;
        .MuiList-root {
            width: 100%;
        }
        .MuiCollapse-container {
            background: rgba(0, 0, 0, 0.05);
        }
    }
    @include md {
        width: 250px;
        align-items: flex-start;
        .MuiList-root {
            width: 100%;
        }
        .MuiCollapse-container {
            background: rgba(0, 0, 0, 0.05);
        }
    }
}
.appBarMain__title {
    display: flex;
    flex-wrap: wrap;
}
.appBarMain__title--brand {
    flex: 1 0 100%;
    font-size: 0.8rem;
    line-height: normal;
}
.appBarMain__title--app-name {
    flex: 1 0 100%;
    text-transform: uppercase;
    line-height: normal;
    font-weight: 600;
    font-size: 1rem;
    margin-left: -2px;
}
.appBarMain-toolbar {
    // mobile view
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
}
.appBar-main_dropmenu {
    .MuiMenu-list {
        li {
            a {
                color: #333;
            }
        }
    }
    .nav-menu_userguide {
    }
    .nav-menu_help {
        font-size: 0.8em;
        border-top: 1px solid #eee;
        span {
            font-size: 0.9rem;
            display: block;
        }
    }
    .nav-menu_version {
        font-size: 0.8rem;
        border-top: 1px solid #eee;
    }
    .nav-menu_usersnippet {
        font-size: 0.8em;
        border-bottom: 1px solid #eee;
        flex-wrap: wrap;
        .name {
            font-size: 1rem;
            display: block;
            overflow: hidden;
            flex: 1 0 100%;
        }
    }
}
.error {
    color: red !important;
}
.no-margin {
    margin: 0 !important;
}
.h100 {
    height: 100%;
}
.w100 {
    width: 100%;
    display: block;
}
.p-1rem {
    padding: 1rem;
}
.p-20 {
    padding: 20px;
}
.p-30 {
    padding: 30px;
}
.pl-1 {
    padding-left: 1rem;
}
.pr-1 {
    padding-right: 1rem;
}
.pt-1 {
    padding-top: 1rem;
}
.pb-1 {
    padding-bottom: 1rem;
}
.mt-1 {
    margin-top: 1rem !important;
}
.mr-1 {
    margin-right: 10px !important;
}
.mb-1 {
    margin-bottom: 1rem !important;
}
.ml-1 {
    margin-left: 1rem !important;
}

.mt-2 {
    margin-top: 20px !important;
}

.absolute-right {
    position: absolute;
    top: 24px;
    right: 0px;
}
.text-right {
    text-align: right !important;
}
.text-transform-none {
    text-transform: none !important;
}
/* Login Page */
.login-page {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.05);
    .login-page-paper {
        max-width: 90%;
        width: 1100px;
        min-height: 60vh;
        background: #fff;
        border-radius: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: wrap;
        overflow: hidden;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
    .login-page__info-section {
        flex: 1 0 50%;
        padding: 2rem;
        display: flex;
        align-items: center;
        .company-logo {
            width: 200px;
            img {
                width: 100%;
            }
        }
        .support {
            padding: 2rem 0;
            .title {
                font-weight: 500;
                font-size: 0.9rem;
                color: $color_primary;
                text-transform: uppercase;
            }
        }
        @include xs {
            order: 1;
        }
    }
    .login-page__form-section {
        flex: 1 0 50%;
        padding: 2rem;
        background-image: linear-gradient(to top, $color_primary 0%, #330867 100%);
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        align-content: center;
        .title {
            flex: 1 0 100%;
            font-size: 1.1rem;
            color: #333;
            margin-bottom: 2rem;
            text-transform: uppercase;
            color: #fff;
        }
        .btns {
            flex: 1 0 100%;
            margin-bottom: 2rem;
            display: flex;
            align-items: center;
            button {
                margin-right: 0.5rem;
                &.btn-white {
                    border-color: white;
                    color: white;
                }
            }
        }
        @include xs {
            order: 0;
            .btns {
                flex-wrap: wrap;
                button {
                    flex: 1 0 100%;
                    margin-bottom: 1rem;
                }
            }
        }
    }
}
/* End: Login Page */
[data-icon]::before {
    content: attr(data-icon);
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 1em;
    display: inline-block;
    vertical-align: middle;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
    -webkit-font-feature-settings: "liga";
}
.alert {
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    margin: 0.5rem 0;
    &.alert-danger {
        color: red;
        background-color: lighten(red, 40%);
    }
}
.input-bg {
    border: none;
    outline: none;
    padding: 0.8rem 1rem;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}
.d-flex {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    &.direction-column{
        flex-direction: column;
    }
    &.justify-center {
        justify-content: center;
    }
    &.justify-around {
        justify-content: space-around;
    }
    &.justify-between {
        justify-content: space-between;
    }
    &.justify-end {
        justify-content: flex-end;
    }
    &.align-top {
        align-items: flex-start;
    }
    &.align-bottom {
        align-items: flex-end;
    }
    &.align-stretch {
        align-items: stretch;
    }
    .d-felx_grow1 {
        flex-grow: 1;
        flex-shrink: 0;
    }
}
.justify-self-end {
    margin-left: auto;
}
.startIcon-noMargin {
    .MuiButton-startIcon {
        margin-right: 0;
    }
}
.tab-main {
    background: #eee;
    .MuiTabs-scrollButtons {
        background: rgba(0, 0, 0, 0.05);
    }
}
.tab-panelContainer {
    overflow: auto;
}

.dropdown-label {
    display: block;
    margin-bottom: 0.4rem;
    font-size: 0.7rem;
    color: #888;
}
/* Show hide action button and icons in table */
.MuiTableRow-hover {
    .action-btn {
        opacity: 0;
    }
    &:hover {
        .action-btn {
            opacity: 1;
        }
    }
}
.MuiAccordionSummary-root.Mui-expanded {
    background-color: #eee;
}
.list-table_basic {
    border-collapse: collapse;
    border-color: #ddd;
    .action-btn {
        .action_icon {
            opacity: 0;
            visibility: hidden;
            &.visible {
                opacity: 1 !important;
                visibility: visible !important;
            }
        }
    }
    .MuiTableRow-root {
        &:hover {
            .action-btn {
                .action_icon {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}

.MuiTableCell-head {
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    letter-spacing: 0.01785714em !important;
    line-height: 1.5rem !important;
    color: #5f6368 !important;
}
.staff_tabel-detail-panel {
    background: #efefef;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    .staff_tabel-detail-item {
        flex: 1;
        padding-right: 1rem;
        border-right: 1px solid #ddd;
        padding: 1rem;
        label {
            font-weight: 500;
            display: block;
            line-height: normal;
            color: rgba(0, 0, 0, 0.5);
            text-transform: uppercase;
            font-size: 0.8rem;
            &.small-case{
                text-transform:none;
            }
        }
        &:last-of-type {
            border: none;
        }
    }
}
.profile-view {
    font-size: 0.8rem !important;
}
.profile-view__left-header {
    width: 100%;
    padding-bottom: 1rem;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
}
.profile-view__left-body {
    padding: 1rem 0;
    &.full-width {
        margin: 0 -12px;
        .listSubHeader {
            padding: 0 12px;
        }
        .MuiTableCell-body {
            padding: 6px 12px !important;
        }
        .section-content {
            border: none;
        }
    }
}

.profile-view__basics {
    color: #222;
    flex-grow: 0;
    flex-shrink: 1;
    font-size: 1rem;
}
.porfile-view__avatar {
    width: 40px;
    height: 40px;
    margin-right: 0.5rem;
    flex-shrink: 1;
    flex-grow: 0;
    box-sizing: border-box;
}
.staff_avatar {
    background: $color_primary !important;
    font-weight: 700;
    font-size: 0.9rem !important;
}
.profile-view__action-right {
    flex-grow: 0;
    flex-shrink: 1;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
}
.profile-view__basics-item {
    &.item-title {
        line-height: normal;
        margin: 0;
        font-size: 1em;
        margin-bottom: 0.5rem;
    }
    &.item-code {
        font-size: 0.8rem;
        margin-bottom: 1rem;
        color: #666;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &.item-info {
    }
    label {
        color: rgba(0, 0, 0, 0.5);
    }
    &::before {
        color: rgba(0, 0, 0, 0.5);
        margin-right: 0.3rem;
        font-size: 1rem;
    }
    .item {
        display: flex;
        margin-bottom: 1rem;
        font-size: 0.8rem;
        &.full-width {
            flex-direction: column;
        }
        label {
            flex: 0 1 30%;
        }
        .value {
            flex: 1;
        }
    }
}
.profile-view__basics-item.item-designation {
    font-size: 0.7rem;
    text-transform: uppercase;
    color: #666;
    margin-bottom: 0.5rem;
}
.profile-view__basics-item.item-email {
    font-size: 0.8em;
}
.profile-view__basics-item.item-employeeid {
    font-size: 0.8em;
}
.profile-view__basics_default-item {
    color: #222;
    margin: 0 0 0.8rem 0 !important;
    line-height: normal;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    label {
        color: rgba(0, 0, 0, 0.5);
        line-height: normal;
        padding-right: 0.5rem;
        width: 40%;
        vertical-align: bottom;
        position: relative !important;
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        &::after {
            content: ":";
            position: absolute;
            right: 0;
            top: auto !important;
        }
    }
    .value {
        width: 73%;
        padding-left: 0.5rem;
        display: flex !important;
        align-items: center;
        column-gap: 0.3rem;
        position: relative;
        .first,
        .middle,
        .last{
            padding:0.5rem 0.3rem 0.4rem;
            border: 1px dotted #ddd;
            line-height:1.1em;
            min-width:60px;
            min-height:26px;
            border-radius:4px;
            &::before{
                content:'';
                font-size:0.65em;
                position:absolute;
                top:-0.4rem;
                background-color:#fff;
                color:#999;
            }
        }
        .first::before{content: 'FIRST';}
        .middle::before{content: 'MIDDLE';}
        .last::before{content: 'LAST';}
    }
}

.profile-view__basics_default-block {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 1rem;
    width: 100%;
    .profile-view__basics_default-item {
        padding: 0.5rem;
        flex-grow: 1;
        flex-shrink: 0;
        label {
            display: block;
            width: 100%;
            font-weight: 500;
            font-size: 0.7em;
            text-transform: uppercase;
            color: #666;
            &::after {
                content: none;
            }
            .sub {
                display: block;
                text-transform: none;
                color: #999;
            }
        }
        .value {
            font-size: 1.1em;
            color: #222;
            padding: 0;
        }
    }
}
.profile-view__basics_card {
    background: rgba(0, 0, 0, 0.025);
    padding: 1rem;
    margin-bottom: 1rem;
    .profile-view__basics_employment-item {
        label {
            width: 50%;
        }
    }
}
.profile-view__basics_cardwhite {
    padding: 1rem 0;
}
.card_tblined {
    box-shadow: none !important;
    padding: 1rem 0 !important;
}
.card_tblined-content {
    padding: 0 !important;
    font-size: 0.8rem !important;
    .MuiTypography-body1 {
        font-size: 0.8rem !important;
    }
}

.mtable-basic {
    .MuiPaper-root {
        box-shadow: none;
        .MuiToolbar-root {
            flex: 1 0 auto;
        }
        .MTable-custom-toolbar {
            flex: 0 1 auto;
        }
    }
    .MuiToolbar-root {
        padding: 0;
        .MuiTypography-h6 {
            text-transform: uppercase;
            margin-bottom: 0.35em;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1.75;
            color: $color_primary;
        }
    }
    .MuiTableFooter-root {
        .MuiTableCell-root {
            border-bottom: none;
        }
    }
}
.MuiTable-root {
    .MuiTableBody-root {
        font-size: 0.875rem;
        .MuiTableRow-root {
            background: rgba(0, 0, 0, 0);
            &:last-child {
                .MuiTableCell-root {
                    border-bottom: none;
                }
            }
            &[level="1"] {
                background: rgba(0, 0, 0, 0.03);
            }
            &[level="2"] {
                background: rgba(0, 0, 0, 0.06);
            }
            &[level="3"] {
                background: rgba(0, 0, 0, 0.09);
            }
            &[level="4"] {
                background: rgba(0, 0, 0, 0.1);
            }
            &[level="4"] {
                background: rgba(0, 0, 0, 0.12);
                .MuiTableCell-body {
                    border-bottom-color: #fff;
                }
            }
        }
    }
}
.card-list {
    counter-reset: itemcount;
    position: relative;
}
.card-list-item {
    font-size: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: top;
    padding: 0 0 5px 30px;
    margin-left: 15px;
    &::before {
        counter-increment: itemcount;
        content: counter(itemcount);
        position: absolute;
        left: 0;
        width: 30px;
        height: 30px;
        background: #ddd;
        color: #fff;
        text-align: center;
        line-height: 30px;
        border-radius: 50px;
    }
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: -20px;
        right: -20px;
        height: 1px;
        background: #eee;
    }
    &:last-of-type {
        &::after {
            height: 0;
        }
    }
    .card-title {
        flex: auto;
        flex-grow: 0;
        display: flex;
    }
    .title {
        margin: 0;
        line-height: 1em;
        padding-right: 5px;
        vertical-align: middle;
        label {
            display: block;
            font-weight: 400;
            text-transform: uppercase;
            font-size: 0.6em;
            color: #999;
        }
        .value {
            vertical-align: top;
        }
    }
    .card-details {
        flex: auto;
        flex-grow: 1;
        margin: 0 -10px;
        padding-left: 30px;
        display: flex;
        justify-content: flex-start;
        .item {
            font-size: 0.8em;
            line-height: 1em;
            padding: 0 10px;
            border-right: 1px solid #eee;
            &:last-child {
                border: none;
            }
            label {
                display: block;
                font-weight: 400;
                font-size: 0.9em;
                color: #999;
                margin-bottom: 5px;
            }
            .value {
                font-size: 1.2em;
                .sub {
                    font-size: 0.8em;
                    color: #999;
                }
            }
        }
    }
    .card-action {
        flex: auto;
        flex-grow: 0;
        text-align: right;
    }
    &.detail-block {
        .card-title {
            flex: 1 0 100%;
        }
        .card-details {
            padding: 10px 0;
            flex: 1 0 80%;
        }
        .card-action {
            flex: 1 0 20%;
        }
    }
}
.modal_default {
    .MuiPaper-root {
        max-width: 100%;
        width: 600px;
    }
    .MuiDialog-container {
        .MuiPaper-rounded {
            border-radius: 10px;
        }
        .MuiDialogTitle-root {
            border-bottom: 1px solid #dddd;
            .MuiTypography-root,
            .title {
                font-size: 0.9rem;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 0.005em;
                color: #333;
            }
        }
    }
    &.large {
        .MuiPaper-root {
            max-width: 100%;
            width: 1200px;
        }
    }
}
.form_group {
    width: 100%;
    margin-bottom: 20px;
    .MuiInput-root,
    .MuiFormControl-root {
        width: 100%;
    }
}
.mTable-toolbar-custom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .MuiToolbar-root {
        flex-grow: 1;
    }
    .MuiButtonBase-root {
        margin-right: 0.5rem;
    }
    .mTable-filter {
        padding: 0 24px 0 8px;
        display: flex;
        align-items: center;
    }
}
.form-inline {
    display: flex;
    .MuiFormControl-root {
        display: block;
        label {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            font-size: 1.2rem;
        }
        .MuiInputBase-root {
            display: inline-block;
            margin: 0;
            vertical-align: middle;
        }
    }
}
.listSubHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
}
.fab-outline {
    border: 1px solid #ddd !important;
    background: #fff !important;
    box-shadow: none !important;
}
.list-table_basic {
    border-collapse: collapse !important;
    .thead {
        background: $color-grey !important;
        .MuiTableCell-head {
            background-color: transparent !important;
            padding: 0.3rem !important;
        }
        .MuiTableCell-head,
        .MuiTableCell-body {
            color: #fff !important;
            font-size: 0.8rem !important;
            line-height: normal !important;
            &.MuiTableCell-sizeSmall {
                padding-left: 0.5rem;
            }
            &.action {
                width: 180px; //$right-col-width
            }
            &.date {
                width: 130px;
            }
        }
    }

    .tbody {
        .MuiTableCell-body {
            &.MuiTableCell-sizeSmall {
                padding-left: 0.5rem;
            }
            .action {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .error {
                font-size: 0.75rem;
            }
        }
    }
}
.tbody-bordered {
    border: 1px solid #ddd;
    border-bottom-color: #ccc;
}
.table-info {
    font-size: 0.8rem;
    color: #327bf0;
}
.table-section-header td {
    padding: 30px 0 0 0 !important;
}
.table-section-header .section-title {
    padding: 10px 0;
}

.form-fullwidth {
    width: 100%;
    height: 100%;
    .MuiFormControl-root {
        width: 100%;
        margin-bottom: 1rem;
    }
}
.form-header {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: 1rem;
    border-bottom: 1px solid #ddd;
}
.form-body {
    padding: 3rem 2rem 1rem 2rem;
}
.form-footer {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1rem;
    border-top: 1px solid #ddd;
}

.drawerList {
    width: $drawer-width;
    padding: 1rem;
    .drawerFormControl {
        width: 100%;
        margin-bottom: 0.5rem;
    }
    .filter-title {
        margin-bottom: 2rem;
    }
    .filterSubmitButton {
        margin-top: 1rem;
    }
}
.worklocationName {
    margin-top: 0.5rem;
}
.btn-oldlink {
    display: flex;
    align-items: center;
    .oldlink {
        background-color: #fff3cb;
    }
}
.Select-menu-outer {
    z-index: 999 !important;
}
.tentry-edit-error {
    color: red !important;
    font-size: 12px;
    display: block;
}
.tentry-error-font {
    font-size: 11px;
    font-weight: normal;
}
@import "staff_db.scss";
@import "timesheet.scss";
.simple-select .dropdown-trigger {
    display: flex !important;
    align-items: flex-end;
}
.simple-select .dropdown-trigger .tag-list {
    flex: 0;
}
.add_review_note_btn {
    float: right;
}
.box-list {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
        flex: 1 0 45%;
        padding: 1rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        margin: 0.5rem;
        max-width: 49%;
        .icon {
        }
        .content {
            .title {
                text-decoration: none;
                color: #666;
                font-size: 1.1rem;
                margin: 0 !important;
                font-weight: 500;
                display: block;
                cursor: pointer;
            }
            .info {
                color: #666;
                font-size: 0.8rem;
                line-height: normal;
            }
        }
    }
}

.compensation-update-info {
    color: grey;
}
.MuiExpansionPanelSummary-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
}
.timeEntry .timeEntry__timeslot .timeEntry__timeslot-slot:last-of-type .col-right.flex-wrap {
    flex-wrap: wrap !important;
}
.timeEntry .timeEntry__timeslot .timeEntry__timeslot-slot .col-right .col-spl {
    flex: 1 0 100%;
    order: 3;
}
.filter-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.filter-column-title {
    font-weight: bold;
    font-size: 1rem;
    color: #333;
    margin-bottom: 1rem;
    display: block;
    overflow: hidden;
}
.d-flex.justify-end {
    justify-content: flex-end;
}
.flex-grow1 {
    flex-grow: 1;
}
.p1 {
    padding: 1rem;
}

.filter-action .filter-column {
    flex-direction: row;
    justify-content: flex-end;
}
.filter-action {
    flex: 1 0 100%;
}
.list-table_basic .thead .MuiTableCell-head p {
    margin: 0;
}
.contact-group .contact-list li .contact-detils .contact-type {
    color: #5f9ea0;
    font-weight: 600;
}
.dropdown-content  {
    max-height: 200px !important;
    overflow-y: auto;
}
.timeSheet-list_header .col-main.justify-between {
    justify-content: flex-start !important;
}
.timeSheet-list_header .col-main .col-item {
    max-width: 200px;
}
.notes {
    display: block;
    width: 100%;
}
.notes .note-item {
    margin-bottom: 1.5rem;
    padding-bottom: 0.3rem;
    border-bottom: 1px solid #ddd;
}
.notes .note-item:last-of-type {
    border: none;
}
.notes .note-item .note-text {
    font-size: 0.9rem;
}
.notes .note-item .notes-labels {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
}
.notes .note-item .notes-labels .title {
    font-size: 0.8rem;
}
.notes .note-item .notes-labels .chip {
    margin: 0.2rem;
    font-size: 0.7rem;
    background: #f9ed89;
    border: 1px solid #e8dd7b;
    padding: 0.2rem 0.5rem;
    border-radius: 50px;
}
.notes .note-item .notes-footer {
    display: flex;
    align-items: center;
    font-size: 0.7rem;
    margin-top: 0.2rem;
}
.notes .note-item .notes-footer .notes-details {
    display: flex;
    color: #666;
}
.notes .note-item .notes-footer .notes-details .item {
    margin-right: 1rem;
}
.role-details-modules-filter {
    margin-top: 20px;
    margin-bottom: 6px;
    margin-left: 8px;
}
.role-details-filter-btn {
    margin-top: 18px !important;
}
.roles-table {
    margin-top: 5px;
}
.roles-table .MuiTableHead-root {
    background-color: #d3d3d3;
}
.role-details-user-search {
    margin-top: 10px;
    margin-bottom: 3px;
    margin-left: 8px;
}
.notes .note-item .notes-footer .notes-action button {
    padding: 0.3rem;
}
.notes .note-item .notes-footer .notes-action button svg {
    font-size: 1rem;
}
.profile-view__left-header {
    padding-bottom: 0.5rem;
}
.profile-view__left-header + .MuiDivider-root {
    background: none;
    border-bottom: 1px dotted #ddd;
}
.profile-view__left-body {
    padding: 0.5rem 0;
}
.profile-view__left-body .profile-view__basics_default {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}

.profile-view__left-body .profile-view__basics_default label {
    display: block;
    width: 100%;
    padding: 0;
}

.profile-view__left-body .profile-view__basics_default label::after {
    content: "";
}

.profile-view__left-body .profile-view__basics_default-item .value {
    padding: 0;
    width: 100%;
}
.profile-view__left-body .profile-view__basics_default-item {
    flex: 1 0 50%;
    display: block;
}
.profile-view__left-body .profile-view__basics_default-item.item-mainproject {
    flex: 1 0 100%;
    border-top: 1px dotted #ddd;
    border-bottom: 1px dotted #ddd;
    padding: 0.5rem 0;
}
.profile-view__left-body .profile-view__basics_default-item.item-role {
    flex: 1 0 30%;
    text-align: center;
}
.profile-view__left-body .profile-view__basics_default-item.item-date_joined {
    flex: 1 0 45%;
    border-left: 1px dotted #ddd;
    padding-left: 0.5rem;
    text-align: center;
}
.profile-view__left-body .profile-view__basics_default-item.item-status {
    flex: 1 0 22%;
    border-left: 1px dotted #ddd;
    padding-left: 0.5rem;
    text-align: center;
}
.section-title {
    text-transform: uppercase !important;
    margin-bottom: 0.35em !important;
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    line-height: 1.75 !important;
    color: #3f51b5 !important;

    &.title-main{
        font-size: 1.3rem !important;
        font-weight: 900 !important;
    }
}
.profile-view__left-body {
    padding: 0;
}
.profile-view__left-body .profile-view__basics_default-item.item-mainproject {
    margin-top: 0 !important;
}

.profile-view__left-body .profile-view__basics_default-item.item-role,
.profile-view__left-body .profile-view__basics_default-item.item-date_joined,
.profile-view__left-body .profile-view__basics_default-item.item-status {
    margin: 0 !important;
    padding: 0.8rem 0;
}
.profile-view__left-body .profile-view__basics_default-item.item-role label,
.profile-view__left-body .profile-view__basics_default-item.item-date_joined label,
.profile-view__left-body .profile-view__basics_default-item.item-status label {
    color: #666;
    font-size: 0.65rem;
    text-transform: uppercase;
}
.profile-view__left-body .profile-view__basics_default-item.item-role .value,
.profile-view__left-body .profile-view__basics_default-item.item-date_joined .value,
.profile-view__left-body .profile-view__basics_default-item.item-status .value {
    font-weight: 500;
    color: #000;
    font-size: 0.9rem;
}
.profile-view__left-body .profile-view__basics_default-item.item-status .value {
    color: #666;
}
.profile-view__left-body .profile-view__basics_default-item.item-status.status-inactive .value {
    color: red;
}
.profile-view__left-body .profile-view__basics_default-item.item-status.status-active .value {
    color: #32a881;
    display: block !important;
}
.profile-view__left-body .profile-view__basics_default-item.item-status .value::before {
    content: "";
    width: 8px;
    height: 8px;
    background: #ddd;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.2rem;
    border-radius: 50%;
}
.profile-view__left-body .profile-view__basics_default-item.item-status.status-active .value::before {
    background: #32a881;
}
.profile-view__left-body .profile-view__basics_default-item.item-status.status-inactive .value::before {
    background: red;
}
.profile-view__left-body {
    .profile-view__basics_default-item {
        &.item-mainproject {
            border-top: 0;
        }
        &.item-fullwidth {
            flex: 1 0 100%;
        }
    }
    .profile-view__basics__main {
        flex: 1 0 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        background: #fff;
        border-radius: 10px;
        margin: 0.5rem 0 1rem 0;
        border: 1px solid #ddd;
        .profile-view__basics_default-item {
            flex: 1 0 33%;
            label {
                justify-content: center;
            }
            &.item-center {
                text-align: center;
                .value {
                    font-weight: 600;
                    display: block !important;
                }
            }
        }
    }
}

.profile-view__basics_default {
    .profile-view__basics_default-item {
        label {
            min-width: 120px;
        }
    }
    .profile-view__basics__main {
        .profile-view__basics_default-item label {
            justify-content: center;
        }
    }
}
.profile-view {
    box-shadow: inset;
    /* box-shadow: inset -7px 0 9px -7px rgba(0,0,0,0.7); */
    /* box-shadow: inset -7px 0 9px -7px rgba(0,0,0,0.7); */
    .profile-view__left-body {
        .profile-view__basics_default {
            .profile-view__basics_default-item {
                label {
                    min-width: auto !important;
                }
            }
        }
    }
}
.profile-view.project-view {
    .profile-view__left-header {
        padding: 0 !important;
        .profile-view__basics {
            .profile-view__basics-item {
                display: flex;
                flex-wrap: wrap;
                .item {
                    margin-bottom: 1rem;
                    flex: 1;
                    display: block;
                    label {
                        display: block;
                    }
                    .value {
                        display: block;
                    }
                    &.full-width {
                        flex: 1 0 100%;
                    }
                }
            }
        }
    }
    .profile-view__left-body {
        .profile-view__basics_default {
            display: block !important;
            .profile-view__basics_default-item {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding: 0.5rem 0;
                margin: 0 !important;
                label {
                    width: auto;
                    flex: 1 0 50%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    min-width: auto !important;
                    &::after {
                        content: ":";
                        padding: 0 0.5rem;
                        position: relative;
                    }
                }
                .value {
                    width: auto;
                    flex: 1 0 50%;
                }
                &.item-fullwidth {
                    display: block;
                    label {
                        margin-bottom: 0.3rem;
                        &::after {
                            content: "";
                            padding: 0;
                        }
                    }
                    .value {
                        line-height: 1.3em;
                    }
                }
                &:last-of-type {
                    border: none;
                }
            }
            .profile-view__basics__main {
                margin: 0 0 0.5rem 0;
                .profile-view__basics_default-item {
                    display: block;
                    border: none;
                    label {
                        display: block !important;
                        &::after {
                            content: "";
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}
/* STEPS logo */
main.login-page {
    background-color: white;
    justify-content: space-between;
}
.login-page .steps-container {
    height: 100vh;
    background-color: #000215;
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding: 3rem;
    flex-wrap: wrap;
}
.login-page .steps-container .steps-logo {
    flex: 1 0 100%;
}
.login-page .steps-container .steps-logo .helper {
    width: 220px;
    overflow: hidden;
}
.login-page .steps-container .steps-info {
    color: #9e9e9e;
    font-size: 1rem;
    margin-top: 0.5rem;
    font-weight: 100;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.login-page .login-page-paper {
    max-width: 40%;
    align-items: center;
    border-radius: 0;
    box-shadow: none;
}
.login-page .login-page__info-section .company-logo {
    width: 160px;
}
.login-page .login-page__info-section,
.login-page .login-page__form-section {
    flex: 1 0 100%;
    padding: 1rem 2rem;
    background-color: transparent;
    background-image: none;
}
.login-page .login-page__info-section .support {
    color: #343434;
    font-size: 0.8rem;
    line-height: 1.2rem;
    font-weight: 400;
}
.login-page .login-page__info-section .support .title,
.login-page .login-page__form-section .title {
    color: #825da5;
    font-size: 0.9rem;
    font-weight: 500;
}
.login-page .login-page__form-section .btns button.MuiButton-containedSecondary {
    background-color: #825da5;
}
.login-page .login-page__form-section .btns button.btn-white {
    color: #825da5;
    border-color: #825da5;
}

@media screen and (max-width: 1024px) {
    main.login-page {
        flex-wrap: wrap;
        align-items: flex-start;
    }
    .login-page .steps-container {
        flex: 1 0 100%;
        height: 200px;
        padding: 0.5rem 0;
        align-items: center;
        justify-content: center;
    }
    .login-page .steps-container .steps-logo {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .login-page .steps-container .steps-logo .helper {
        width: 100px;
    }
    .login-page .steps-container .steps-info {
        font-size: 0.75rem;
        margin-top: 0;
    }
    .login-page .login-page-paper {
        max-width: 100%;
    }
    .login-page .login-page__info-section .support .title,
    .login-page .login-page__form-section .title {
        text-align: center;
        margin-bottom: 1rem;
    }
    .login-page .login-page__info-section,
    .login-page .login-page__form-section {
        justify-content: center;
        text-align: center;
    }
    .login-page .login-page__info-section .company-logo {
        margin: 0 auto;
    }
    .login-page .login-page__form-section .btns {
        justify-content: center;
    }
}

.steps-bar {
    width: 100%;
    height: 35px;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
}
.steps-bar .steps-logo .helper {
    height: 25px;
    width: 150px;
    overflow: hidden;
    img {
        height: 100%;
        margin: 0 10px;
    }
}
.steps-bar .info {
    color: #eaeaea;
    font-weight: 100;
}
.steps-bar .steps-logo {
    width: auto;
    flex-grow: 0;
    flex-shrink: 1;
}

.appBarHelper {
    min-height: 103px !important;
}

@media (min-width: 0px) and (orientation: landscape) {
    .appBarHelper {
        min-height: 83px !important;
    }
}
@media (min-width: 600px) {
    .appBarHelper {
        min-height: 103px !important;
    }
}
.appBarLogo {
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    .helper {
        max-width: 120px;
        img {
            width: 100%;
            filter: brightness(10);
        }
    }
}
@media (min-width: 600px) {
    .appBarLogo {
        .helper {
            img {
                filter: brightness(1);
            }
        }
    }
}

// Steps styles
$steps_font-size: 0.9rem;
$steps_input_font-size: 0.9rem;
$steps_color_primary: $color_primary;
$steps_color_grey: #ececec;
$steps_bg_grey: #ebecf0;
$steps_font_color_dark: #172b4d;
$steps_font_color_light: #6b778c;

@mixin disableUnderline {
    .MuiInput-underline:before,
    .MuiInput-underline:after {
        content: "" !important;
        border: none !important;
    }
}
@mixin stepsInput {
    font-size: $steps_input_font-size;
}
.st-HSpacingL_1 {
    padding-left: 0.5rem !important;
}
.st-HSpacingR_1 {
    padding-right: 0.5rem !important;
}
.st-HSpacingL_2 {
    padding-left: 1rem !important;
}
.st-HSpacingR_2 {
    padding-right: 1rem !important;
}
.st-HSpacingL_3 {
    padding-left: 1.5rem !important;
}
.st-HSpacingR_3 {
    padding-right: 1.5rem !important;
}
.st-HSpacingL_4 {
    padding-left: 2rem !important ;
}
.st-HSpacingR_4 {
    padding-right: 2rem !important;
}

.st-action_bar {
    display: flex;
    .st-action_item {
    }
}
.st-timeEntry_wrapper {
    font-size: $steps_font-size;
    color: $steps_font_color_dark;
    .st-action_bar {
        margin-bottom: 10px;
    }
    .st-timeEntry {
        background-color: #f3f4f6;
        padding: 5px;
        border-radius: 4px;
        margin-bottom: 30px;
        .st-timeEntry_row-footer,
        .st-timeEntry_row-header {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .col-summary {
                width: $right-col-width;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 0.9rem;
                .dayTotal {
                    font-size: 0.75rem;
                    .number {
                        font-size: 1.3em;
                        font-weight: 600;
                        display: inline-block;
                    }
                }
                .row-action {
                    margin-left: auto;
                }
            }
            .col-info-notification {
                flex-grow: 1;
            }
        }
    }
    &.time-inout {
        .st-timeEntry {
            margin: 0 !important;
            .st-timeEntry_entry {
                .st-timeEntry_entry-item {
                    .input-container_left,
                    .input-container_right {
                        padding: 1rem !important;
                    }
                    .input-container_left {
                        flex-direction: row;
                        justify-content: flex-start;
                        .te-row {
                            margin-right: 2rem;
                            &:last-of-type {
                                margin: 0;
                            }
                        }
                    }
                    .input-container_right {
                        .te-row {
                            align-items: center !important;
                            .te-time {
                                padding: 0.5rem 1rem;
                                background: #eee;
                                border-radius: 6px;
                                border: 1px solid #eee;
                            }
                        }
                    }
                }
            }
        }
    }
}
.st-timeEntry_date {
    @include disableUnderline;
    .timeEntry-dateInput {
        max-width: 150px;
        margin: 0 !important;
        background: $steps_color_primary;
        border-radius: 4px;
        padding: 0 0 0 0.5rem;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        button,
        input {
            color: #fff;
            @include stepsInput;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
    }
}
.st-timeEntry_payperiod-title {
    font-weight: 600;
    line-height: $steps_font-size;
    .label {
        display: block;
        overflow: hidden;
        font-size: 0.75em;
        text-transform: uppercase;
        color: $steps_font_color_light;
    }
    &.center {
        .label {
            text-align: center;
        }
    }
}
.st-timeEntry_inout {
    .st-timeEntry_inout-btn {
        button {
            text-transform: none;
            box-shadow: none;
            background-color: $steps_color_grey;
            color: $steps_font_color_dark;
            .label {
                font-weight: normal !important;
            }
            .value {
                margin-left: 0.5rem;
            }
        }
    }
}
.st-timeEntry_footer {
    display: flex;
    width: 100%;
    padding: 0.5rem 0;
    .st-timeEntry_footer-item {
        &.middle {
            flex-grow: 1;
        }
        &.right {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .st-timeEntry_action {
                button {
                    margin-left: 0.5rem;
                }
            }
        }
    }
    .hours-summary {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 1rem;
        .title {
            text-transform: uppercase;
            font-size: 0.8rem;
            font-weight: 500;
            width: 100px;
            text-align: right;
            line-height: normal;
            color: $steps_font_color_light;
        }
        .summary {
            font-size: 0.7rem;
            display: flex;
            background-color: #ddd;
            margin-left: 0.5rem;
            border-radius: 4px;
            .item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                line-height: normal;
                border-right: 1px dotted #fff;
                &:last-child {
                    border: noe;
                }
                .label,
                .value {
                    padding: 0.2rem 0.4rem;
                }
                .label {
                    strong {
                        display: block;
                    }
                }
                .value {
                    font-size: 1.2em;
                }
            }
            &.error {
                background-color: #ffd9d9;
                .item:last-child strong {
                    color: red;
                }
            }
            &.success {
                background-color: #dbecd0;
                .item:last-child strong {
                    color: green;
                }
            }
            &.error,
            &.success {
                .label,
                .value {
                    color: $steps_font_color_dark;
                }
            }
        }
    }
    .hours-summary-table {
        font-size: 0.7rem;
        line-height: 0.7rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 1rem;
        table {
            th,
            td {
                text-align: center;
                &.text-right {
                    text-align: right;
                }
                &.error {
                    color: red;
                }
                &.success {
                    color: #05873c;
                }
            }
            th {
                background-color: #ddd;
                padding: 0 0.5rem;
            }
            td {
                padding: 0 0.5rem;
                border-right: 1px dotted #ddd;
            }
        }
    }
}
// timesheet row
.te-input {
    @include disableUnderline;
}
.ts-input {
    @include disableUnderline;
    .MuiFormControl-root {
        width: 100% !important;
    }
    .MuiTextField-root,
    .input-item {
        background-color: #f8f8f8 !important;
        border: 1px solid #ebebeb !important;
        border-radius: 4px !important;
        flex-grow: 1 !important;
        padding: 2px 10px !important;
    }
    fieldset {
        border: none !important;
    }
}
.vtop {
    vertical-align: top !important;
}
.st-timeEntry_entry-item {
    background-color: #fff;
    display: flex;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    margin-bottom: 5px;
    .input-container_left,
    .input-container_right {
        padding: 5px;
    }
    .input-container_left {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .input-container_right {
        width: $right-col-width;
        min-width: $right-col-width;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .te-row {
            flex-grow: 1;
            align-items: flex-start;
            align-content: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }
    .te-row {
        display: flex;
        align-items: flex-start;
    }
    .te-tree-btn {
        display: flex;
        align-items: center;
        background: #eee;
        padding: 0.3rem;
        border-radius: 4px;
        order: 2;
    }
    .te-task {
        flex-grow: 1;
        order: 1;
        .MuiInputLabel-formControl {
            top: 0.5rem;
            font-size: 0.9rem;
            left: auto;
            position: absolute;
            transform: unset;
            &.MuiFormLabel-filled {
                display: none;
            }
            & + .MuiInput-formControl {
                margin: 0 !important;
            }
        }
        .input-dropdown {
            width: 100%;
            background-color: #f8f8f8;
            border: 1px solid darken(#f3f3f3, 3%);
            padding: 0 0.5rem;
            border-radius: 4px;
            @include disableUnderline;
            input {
                font-size: 0.9rem;
                padding: 0.5rem 0;
                color: #000;
            }
        }
        .MuiFormHelperText-root {
            font-weight: 500;
            color: rgba(0, 0, 0, 0.5);
            margin: 0.2rem 0 0.2rem 5px;
        }
    }
    .te-location {
        background-color: #f8f8f8;
        border: 1px solid darken(#f3f3f3, 3%);
        order: 3;
        margin-left: 0.5rem;
        .MuiInput-input {
            font-size: 0.9rem;
        }
    }
    .te-description {
        margin-top: 0.5rem;
        background-color: #f8f8f8;
        border: 1px solid darken(#f3f3f3, 3%);
        border-radius: 4px;
        flex-grow: 1;
        textarea {
            width: 100%;
            padding: 0.3rem;
            border: none;
            background: transparent;
            font-family: "Roboto", sans-serif;
            font-size: 0.9rem;
            &:focus {
                outline: none;
                border: 1px solid #66afe9;
            }
        }
        .tentry-edit-error {
            padding-left: 0.5rem;
        }
    }
    .te-resolve {
        display: flex;
        align-items: center;
        padding: 0.2rem 0.5rem 0.2rem 0;
        margin: 0.5rem 0 0 0.5rem;
        background-color: #e2ebd1;
        border-radius: 4px;
        label {
            margin: 0;
            .checkbox {
                padding: 0.2rem !important;
                font-size: 0.9rem;
            }
            .MuiFormControlLabel-label {
                font-size: 0.9rem;
            }
        }
    }
    .te-tag {
        display: flex;
        align-items: center;
        margin-top: 0.8rem;
        .ta-tag-title {
            text-transform: uppercase;
            font-size: 0.7rem;
            color: $steps_font_color_light;
            margin-right: 0.5rem;
        }
        .tags {
            display: flex;
            align-items: center;
            .tags-tag {
                font-size: 0.8rem;
                margin-left: 0.3rem;
                .btn-tags-delete {
                    font-size: 0.85rem;
                    margin-left: 0.3rem;
                    color: darken($steps_color_grey, 5%);
                }
                &:hover {
                    .btn-tags-delete {
                        color: darken($steps_color_grey, 50%);
                    }
                }
                padding: 0.2rem 0.3rem;
                background-color: $steps_color_grey;
            }
        }
        .btn-tags-add {
            height: 26px !important;
            border-radius: 4px;
            box-shadow: none;
            margin-left: 0.3rem;
            background-color: $steps_font_color_dark;
        }
        .tag-col-tags {
            // time entry edit popup
            display: flex;
            align-items: center;
            .tag-input {
                width: 150px;
                .MuiFormControl-root {
                    width: 100%;
                }
            }
        }
    }
    .te-time {
        @include disableUnderline;
        max-width: 100px;
        .te-input {
            background-color: #f8f8f8;
            border: 1px solid darken(#f3f3f3, 3%);
            input {
                padding: 0.4rem 0.5rem;
                &:focus {
                    outline: none;
                    border: 1px solid #66afe9;
                }
            }
        }
    }
    .te-timeinfo {
        flex: 1 0 100%;
        .te-helper {
            font-size: 0.7rem;
            margin: 0.2rem 0 0 0;
        }
    }
}
.tree-view-drawer {
    width: 350px;
    padding: 1rem;
    .drawer-title {
        font-size: 1.2rem;
        font-weight: 600;
        width: 100%;
        display: block;
        margin-bottom: 20px;
    }
}
.timesheet-list_row-date {
    text-align: center;
    .m {
        display: block;
    }
    .d {
        text-align: center;
        display: block;
        height: 36px;
        width: 36px;
        padding: 3px;
        border-radius: 50%;
        overflow: hidden;
        line-height: 0.9rem;
        cursor: pointer;
        i {
            font-style: normal;
            display: block;
        }
    }
    &:hover {
        .d {
            background: #eee;
        }
    }
}

.project-column-grid .project-column-title {
    font-size: 0.8rem;
    color: #999;
}
.project-column-grid .react-dropdown-tree-select .dropdown-trigger {
    border: none;
    padding: 0;
    display: flex;
    align-items: flex-end;
}
.project-column-grid .react-dropdown-tree-select .dropdown-trigger .tag-list .tag-item {
    margin: 4px 0 0 0;
    width: 100%;
}

.project-column-grid .react-dropdown-tree-select .dropdown-trigger .tag-list .tag-item input.search {
    width: 100%;
    padding: 0.3rem 0;
    border-bottom-color: #666;
}
.project-column-grid {
    display: flex;
    align-items: flex-end;
    padding: 0 12px !important;
    margin-bottom: 12px;
}

.page-flex {
    display: flex;
    align-items: stretch;
    min-height: inherit;
}

.page-flex .chp-sidebar {
    flex: 0 0 200px;
    border-right: 1px solid #ddd;
    ul.sidebar-menu {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            background-color: none;
            a {
                padding: 0.5rem 1rem;
                border-top-left-radius: 50px;
                border-bottom-left-radius: 50px;
                color: #333;
                display: block;
                cursor: pointer;
                overflow: hidden;
                &:hover {
                    text-decoration: none;
                    color: #3f51b5;
                }
                &.active {
                    background-color: #ddd;
                    color: #222;
                }
            }
        }
    }
}

.page-flex .chp-form {
    flex: 1;
    padding-left: 1rem;
}
@media screen and (max-width: 540px) {
    .st-action_bar {
        flex-wrap: wrap;
        .st-action_item {
            &.st-timeEntry_date {
                flex-grow: 0;
                order: 0;
            }
            &.st-timeEntry_payperiod {
                flex: 1 0 50%;
                order: 1;
            }
            &.st-timeEntry_inout {
                flex: 1 0 50%;
                margin: 1rem 0 0 0;
                order: 2;
                .st-HSpacingL_4 {
                    padding: 0 !important;
                }
                button {
                    width: 100%;
                }
            }
            &.st-timeEntry_request {
                margin: 1rem 0 0 0;
                order: 3;
                .st-HSpacingL_4 {
                    padding-left: 1rem !important;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .st-timeEntry_entry {
        .st-timeEntry_entry-item {
            flex-direction: column;
            .input-container_left,
            .input-container_right {
                width: 100%;
            }
        }
    }
    .st-timeEntry_row-footer {
        .col-summary {
            width: 100% !important;
        }
    }
    .st-timeEntry_footer {
        flex-wrap: wrap;
    }
    .st-timeEntry_footer {
        .st-timeEntry_footer-item {
            flex-wrap: wrap;
            .st-timeEntry_action {
                flex: 1 0 100%;
            }
            &.left {
                flex: 1 0 50%;
            }
            &.middle {
                flex: 1 0 50%;
                text-align: right;
            }
            &.right {
                flex: 1 0 100%;
                .st-timeEntry_action {
                    display: flex;
                    button {
                        flex-grow: 1;
                        margin: 0 0.5rem 0 0;
                        &:last-of-type {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}
.actions-bottom {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
    align-items: center;
}
.actions-bottom button {
    margin-left: 5px;
}
.timeSheet-list_header .col-action.col-summary {
    width: 120px;
}
.st-timeEntry_entry-item .te-task-helper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.st-timeEntry_entry-item .te-task-helper svg {
    font-size: 0.9rem;
    color: #999;
}
.section-title {
    font-weight: 700 !important;
    font-size: 1rem !important;
    color: $color-primary;
    @include xs {
        font-size: 0.8rem !important;
        line-height: normal !important;
    }
}
.section-main {
    &.section-main-header-fixed {
        padding-bottom: 50px;
    }
    .listSubHeader {
        align-items: flex-end;
        min-height: 40px;
    }
    .section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 1rem;
        .col-left {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .section-title-wrap {
                p,.section-title-sub {
                    font-size: 0.9em;
                    color: teal;
                    font-weight: 600;
                }
            }
            .section-title {
                margin: 0 !important;
                line-height: normal !important;
            }
            button {
                margin-right: 0.5rem;
            }
        }
        .actions {
            button {
                margin-left: 0.5rem;
            }
            @include xs {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                * {
                    margin: 0.2rem 0;
                }
            }
        }
    }
    .section-content {
        border: 1px solid #dadce0;
        margin-top: 0.5rem;
        border-radius: 6px;
        .section-title {
            margin: 0;
        }
        overflow: auto;
    }
    .header-fixed {
        position: fixed;
        height: 55px;
        background: #fff;
        width: 100%;
        left: 0;
        right: 0;
        padding: 0.5rem 1rem;
        margin-top: -15px;
        z-index: 1;
        border-bottom: 1px solid #ddd;
        .section-header {
            .col-left {
                order: 0;
                flex-grow: 0;
                .stretched {
                    align-items: stretch !important;
                }
            }
            .show-on-scroll {
                order: 1;
                flex-grow: 1;
                padding-left: 0.5rem;
                font-weight: 500;
                color: #3f51b5;
                display: flex;
                align-items: center;
                &:before {
                    content: "|";
                    margin-right: 0.5rem;
                    color: #ccc;
                }
            }
            .actions {
                order: 2;
            }
            .employee {
                &.bordered{
                    padding: 0 0.5rem 0 0.5rem;
                    background-color: rgba(0, 0, 0, 0.1);
                    border-radius: 200px;
                }
            }
        }
        @include xs {
            height: auto;
            margin-top: -0.8rem;
            .section-header {
                margin: 0;
                .col-left {
                    flex: 1 0 100%;
                    margin-bottom: 0.5rem;
                    flex-wrap: nowrap;
                    .section-title {
                        font-size: 0.75rem !important;
                        text-transform: initial !important;
                    }
                }
                .actions {
                    button {
                        &:first-of-type {
                            margin: 0;
                        }
                    }
                }
                .show-on-scroll {
                    padding-left: 0 !important;
                    max-width: 42%;
                    font-size: 0.8rem;
                    line-height: normal;
                    &::before {
                        content: none;
                    }
                }
            }
            + .section-body {
                top: 100px;
            }
        }
    }
}
.section-main .header-fixed + .section-body {
    position: relative;
    @include lg {
        top: 50px !important;
    }
}
// Updated
.list-standard {
    margin: 0;
    padding: 0;
    list-style: none;
    .list-item {
        margin-bottom: 0.5rem;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: nowrap;
        label {
            flex: 0 0 35%;
            color: #666;
            padding-right: 1.5rem;
        }
        .value {
            text-align: left;
            flex-grow: 0;
            min-width: 100px;
            &.right {
                text-align: right;
                min-width: auto;
                width: 80px;
            }
            .pill {
                padding: 0.2rem 0.8rem;
                border: 1px solid #ddd;
                margin: 0.2rem;
                margin-left: 0;
                border-radius: 20px;
                display: inline-block;
            }
        }
        &.title {
            label {
                font-weight: 500;
                font-size: 0.875rem;
            }
            .value {
                font-size: 0.875rem;
                font-weight: 500;
                color: #3f51b5;
            }
        }
    }
    @include xs {
        .list-item {
            font-size: 0.7rem;
            margin-bottom: 0.2rem;
            flex-wrap: wrap;
            label {
                flex: 0 0 45%;
                color: #666;
            }
            .value {
                flex-grow: 1;
                text-align: left;
            }
        }
    }
}
// Updated
.box-details {
    display: flex;
    ul.list-standard {
        min-width: 25%;
        display: flex;
        flex-direction: column;
        flex: 1;
        &.list-ul-border-l {
            border-left: 1px solid #ddd;
            padding-left: 1rem;
        }
        &.list-ul-shrink {
            flex-grow: 0;
            flex-shrink: 1;
        }
        &.list-ul-grow {
            flex-grow: 1;
            flex-shrink: 0;
        }
        .list-item {
            label {
                min-width: 150px;
                text-transform: uppercase;
                font-size: 0.9em;
            }
            &.list-item-block {
                flex-direction: column;
            }
            &.list-item-bottom-info {
                justify-self: flex-end;
                margin-top: auto;
                border-top: 1px solid #ddd;
                padding-top: 1rem;
                font-size: 0.9rem;
                flex-direction: column;
            }
        }
    }
    @include xs {
        flex-wrap: wrap;
        ul.list-standard {
            flex: 1 0 100%;
            &.list-ul-border-l {
                border-left: none;
                padding-left: 0;
                .list-item {
                    &.list-item-block {
                        flex-direction: row;
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }
}

.st-action_bar.st-action_bar-top {
    position: fixed;
    z-index: 1;
    margin-top: -70px;
    background: #fff;
    margin-bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 1rem;
    border-bottom: 1px solid #ddd;
    height: auto;
}

.st-timeEntry {
    margin-top: 55px;
    padding-top: 1rem;
}
@media (max-width: 540px) {
    .st-timeEntry_wrapper {
        padding-top: 90px;
        &.time-inout {
            padding-top: 0 !important;
            .st-timeEntry {
                .st-timeEntry_entry {
                    .st-timeEntry_entry-item {
                        .input-container_left {
                            flex-direction: column !important;
                            padding-bottom: 0.5rem !important;
                            .te-row {
                                margin: 0;
                                flex: 1 0 100%;
                                margin-bottom: 1rem;
                                .te-item {
                                    width: 100%;
                                    .MuiFormControl-root {
                                        width: 100%;
                                    }
                                }
                                &:last-of-type {
                                    margin-bottom: 0 !important;
                                }
                            }
                        }
                        .input-container_right {
                            border-left: none !important;
                            padding-top: 0.5rem !important;
                            padding-bottom: 0.5rem i !important;
                        }
                    }
                }
            }
        }
    }
    .st-action_bar.st-action_bar-top {
        margin-top: 0;
        top: 90px;
    }

    .st-timeEntry {
        margin-top: 0;
        padding-top: 0;
    }
}
.steps-boxed-input {
    padding: 0.1rem;
    background: #eee;
    border-radius: 6px;
    border: 1px solid #ddd;
    .MuiInput-underline {
        &::before,
        &::after {
            content: none !important;
        }
    }
}
.table-bordered {
    border-color: #ddd;
    border: none;
}
table.no-left-pad {
    thead,
    tbody {
        th,
        td {
            padding-left: 0;
        }
    }
}
.steps-bar .btn-oldlink {
    position: absolute;
    right: 1rem;
}
.steps-bar .btn-oldlink .oldlink:hover {
    color: #ddd;
}
.steps-bar .btn-oldlink .oldlink {
    background: transparent;
    color: #fff;
    border: 1px solid rgba(255, 255, 225, 0.5);
    border-radius: 5px;
    font-size: 0.8rem;
    text-transform: none;
    font-weight: 100;
}

@media (max-width: 991px) {
    .steps-bar .btn-oldlink {
        display: none;
    }
}

.ml10 {
    margin-left: 10px;
}

.ml4 {
    margin-left: 4px !important;
}
.te-req-error {
    border: 1px solid #fa8072 !important;
}
.te-input-focused {
    border: 1px solid #66afe9 !important;
}
.list-table_basic .MuiTableRow-root {
    vertical-align: top;
}
.timesheet-list_row-locked{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.timesheet-list_row-locked svg{
    width: 0.7em;
    fill: crimson;
}
.timesheet-list_row-date {
    display: block;
}
.list-table_basic .tbody .MuiTableCell-body .action {
    align-items: flex-start !important;
}
.list-table_basic .tbody .MuiTableCell-body .action .timesheet-list_row-description {
    font-size: 0.7rem;
    color: #999;
    text-align: right;
}
.list-table_basic .tbody .MuiTableCell-body .action .action-btn {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
}
.timesheet-list_row-date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    line-height: 1.2em;
    background-color: #eee;
    padding: 0.2rem;
    border-radius: 6px;
    text-transform: none;
    font-size: 0.8em;
    margin-bottom: 0.5rem;
    color: #333;
}
span.timesheet-list_row-date .dm {
    font-weight: 600;
    font-size: 1.2em;
}

.timesheet-list_row-date .dm .dd {
    display: block;
}
.th-date,
.td-action {
    padding: 0.5rem !important;
}
.list-table_basic .tbody .MuiTableCell-body .action {
    flex-direction: column;
    align-items: flex-end !important;
}

.list-table_basic .tbody .MuiTableCell-body .action .action-btn {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    transition: all ease 0.4s;
}

.list-table_basic .thead .MuiTableCell-head.action {
    text-align: right;
}

.Mui-focused .input-dropdown {
    border-color: #77aee4 !important;
}
.st-timeEntry_entry-item .te-description textarea.te-description-textarea,
.st-timeEntry_entry-item .te-time .te-input input {
    border: 1px solid transparent;
    border-radius: 4px;
}
.st-timeEntry_entry-item .te-description textarea.te-description-textarea:focus,
.st-timeEntry_entry-item .te-time .te-input input:focus {
    border: 1px solid #77aee4 !important;
}
.te-item.te-description {
    display: flex;
    flex-wrap: wrap;
}
.te-item.st-HSpacingL_2.te-location {
    padding-left: 0 !important;
}
.te-item.st-HSpacingL_2.te-location .te-input select {
    padding-left: 0.5rem;
}
.te-charge-code {
    color: #9e8964;
}
.timesheet-list_row-description span {
    display: block;
}
.te-charge-code {
    margin-bottom: 0.4rem;
}

.link {
    cursor: pointer;
    text-decoration: none;
}
.link:hover {
    color: #3788d8;
}

.steps-tab {
    margin-bottom: 20px;
}
.steps-table thead tr th.MuiTableCell-head {
    font-size: 0.92rem !important;
    border-bottom-color: #666;
    color: #000 !important;
}
.text-submitted {
    color: #327bf0;
}
.text-approved {
    color: #00c73c;
}
.text-rejected {
    color: #ed2400;
}
.text-cancelled {
    color: #999;
}

.td-active {
    background-color: #f9e2ba !important;
}
.mdl-demo .dropdown-content {
    z-index: 1000 !important;
}
.st-row {
    display: flex;
    align-items: stretch;
    .section-title {
        font-weight: bold;
        margin-bottom: 1rem;
    }
    .st-aside {
        flex: 1;
        .section-title {
            margin-bottom: 1rem;
        }
        .box-notification-list {
            .list-item {
                .item-content {
                    .item-list-container {
                        .item {
                            .action {
                                opacity: 0;
                            }
                            .link {
                                &:hover {
                                    .action {
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .st-col-main {
        margin-left: 1rem;
        flex: 1;
        .box-request-wrapper {
            padding: 2rem;
            margin-bottom: 1rem;
            border-radius: 6px;
            .section-title {
                margin-bottom: 1rem;
            }
        }
    }
    .box-notification-list {
        padding: 0;
        .list-item {
            border-radius: 6px;
            margin-bottom: 2rem;
            border: 1px solid #ddd;
            .item-header {
                padding: 0.5rem 1rem;
                background-color: #eee;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .title {
                    font-size: 1rem;
                    font-weight: bold;
                    color: #222;
                }
                .desc {
                    font-size: 0.8rem;
                    color: #666;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
                .action {
                    .table-info {
                        color: #666;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        margin-left: auto;
                        svg {
                            width: 16px;
                            height: 16px;
                            fill: #666;
                        }
                    }
                }
            }
            .item-content {
                padding: 1rem;
                .item-list-container {
                    .item {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-bottom: 0.5rem;
                        margin-bottom: 0.5rem;
                        border-bottom: 1px solid #ddd;
                        &:last-of-type {
                            border: none;
                        }
                        .details {
                            flex-grow: 1;
                            .title {
                                font-size: 1rem;
                                font-weight: bold;
                                color: #222;
                            }
                            .desc {
                                font-size: 0.8rem;
                                color: #666;
                                display: block;
                                .date {
                                    font-size: 1rem;
                                    color: #000;
                                }
                                .info {
                                    margin-left: 1rem;
                                    font-style: italic;
                                }
                            }
                        }
                        .action {
                            flex-grow: 0;
                            text-transform: uppercase;
                            font-weight: 600;
                            color: #666;
                        }
                        .link {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            &:hover {
                                .action {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .box-request-item-list {
        .box-request-item {
            padding: 0.5rem;
            margin-bottom: 0.5rem;
            border-radius: 4px;
            background-color: #eee;
            display: flex;
            justify-content: space-between;
            .content {
                flex: 1;
            }
            .action {
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }
}

.text-bold {
    font-weight: bold !important;
}
tr.text-bold {
    td,
    th {
        font-weight: bold !important;
    }
}

// Temporary style added. Need to remove this
.temp-email-action {
    margin-top: 150px;
}
.MuiAutocomplete-popper {
    margin-bottom: 0.5rem;
}
/* --------- Start: fullcalendar --------- */
.fc .fc-button-primary {
    background: #3f51b5;
    box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}
.fc .fc-daygrid-event-harness.fc-day-total {
    position: absolute;
    left: 0;
    bottom: 0;
}
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    //position: static !important;
}
.min-hour-sucess,
.min-hour-error, .min-hour-warning {
    background: transparent !important;
    border: none !important;
}
.min-hour-sucess .fc-event-title,
.min-hour-success-label {
    color: green !important;
}
.min-hour-error .fc-event-title,
.min-hour-error-label {
    color: red !important;
}
.min-hour-warning .fc-event-title,
.min-hour-warning-label {
    color: #FFBF00 !important;
}
.fc-holiday .fc-daygrid-day-top .fc-daygrid-day-number {
    color: red;
}
.fc-day-sat .fc-daygrid-day-top .fc-daygrid-day-number,
.fc-day-sun .fc-daygrid-day-top .fc-daygrid-day-number {
    color: #e89494;
}

.req-calendar {
    .fc-event-title {
        color: #000 !important;
        display: flex;
        align-items: center;
        svg {
            width: 15px;
            margin-right: 0.3rem;
        }
    }
}
.fc-day-today {
    border: 3px solid black !important;
    background-color: transparent !important;
}
.fc-worklocation::before  {
    content: '\e0c8';
    font-family: "Material Icons";
    font-weight: 900;
    font-size: large;
    position: absolute;
}
/* ------------- End: fullcalendar --------------- */

/* --------- Menu notifications ---------------*/
.nav-menu_notification {
    display: flex !important;
    font-size: 0.85rem !important;
    white-space: normal !important;
    max-width: 250px !important;
    line-height: normal !important;
    align-items: flex-start !important;
}

.nav-menu_notification .material-icons {
    margin-right: 0.5rem !important;
    color: rgba(0, 0, 0, 0.6) !important;
}
/* --------- End: Menu notifications ---------------*/
.expense-margin-top {
    margin-top: 20px;
}
.expense-add-label {
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
}
.expense-milage-info {
    margin-top: 15px;
    font-family: sans-serif;
}
/*Styles for expense voucher list page*/
.submit {
    background-color: #b1e0fe;
}
.Paid {
    background-color: #f2f4f3;
    padding: 3px 22px;
}
.Saved {
    background-color: #fcea87;
    padding: 3px 16px;
}
.Submitted {
    background-color: #b1e0fe;
    padding: 3px 8px;
}
.Approved {
    background-color: #85dc56;
    padding: 3px 10px;
}
.Rejected {
    background-color: #efb1b1;
    padding: 3px 10px;
}
.papprove {
    background-color: #d2f0c2;
    padding: 3px 8px;
}
.preject {
    background-color: #f2bfbf;
    padding: 3px 8px;
}
.fc-event-title-container {
    white-space: normal !important;
}
.bg-black {
    background-color: #000 !important;
    td {
        color: #fff !important;
    }
}
.table-light-bordered {
    border-color: #f9ebeb2e !important;
    border: none;
}

.fc .fc-toolbar-title  {
    font-size: 1.2em;
    margin: 0;
    text-transform: uppercase;
    color: #666;
}
table.fc-scrollgrid,
table.fc-scrollgrid td,
table.fc-scrollgrid th {
    border-color: #999 !important;
}
.mdl-demo .dropdown-content {
    width: 100%;
}
.dropzone-container {
    label {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.58);
        line-height: normal;
        margin-bottom: 0.2rem;
        display: block;
    }
    .dropzone {
        text-align: center;
        background: #f8f8f8;
        border: 1px solid #e8e8e8;
        padding: 1rem;
        border-radius: 4px;
        .btn {
            padding: 0.4rem 0.6rem;
            background: transparent;
            border: 1px solid #999;
            border-radius: 4px;
            margin: 0.2rem;
        }
    }
    .files {
        .title {
            font-weight: normal;
        }
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                display: flex;
                align-items: center;
                margin-bottom: 0.5rem;
                .file-name {
                    max-width: 80%;
                    line-height: normal;
                }
                button {
                    margin-left: 0.5rem;
                }
            }
        }
    }
}

.btn-attachment {
    display: inline-block;
    padding: 0.2rem;
    font-size: 0.7rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 0.2rem;
    a {
        margin: 0 0.2rem;
        vertical-align: middle;
        display: inline-block;
        line-height: 0.7rem;
        svg {
            position: relative;
            top: 2px;
        }
    }
}

.table-header {
    padding: 1rem;
    display: flex;
}
.table-header .col-left {
    display: flex;
    align-items: center;
}
.table-header .section-title {
    margin: 0 !important;
}

.project-task-detail-panel {
    padding: 1rem;
    background: #f1f3f4;
    .profile-view__basics_default {
        .profile-view__basics_default-item {
            margin: 0 0 1rem 0;
            label,
            .value {
                display: block;
                width: 100%;
                padding: 0;
            }
            label {
                margin-bottom: 0.2rem;
                &::after {
                    content: "";
                }
            }
        }
    }
}
label.rdl-control-label {
    text-transform: uppercase;
    font-weight: 500;
    &::after {
        content: " Resources";
    }
}
.rdl-available label.rdl-control-label {
    color: #3f51b5;
}
.notification-users label.rdl-control-label {
    text-transform: uppercase;
    font-weight: 500;
    &::after {
        content: " Users";
    }
}
.notification-groups label.rdl-control-label {
    text-transform: uppercase;
    font-weight: 500;
    &::after {
        content: " Groups";
    }
}
.form-group-readonly label {
    display: block;
    overflow: hidden;
    color: #666;
}

.form-group-readonly .value {
    font-size: 1.1rem;
    font-weight: 500;
}

.form-group-readonly {
    margin-bottom: 0.5rem;
}

.task-resource-box {
    .header {
        label {
            font-weight: bold;
            text-transform: uppercase;
            color: #666;
        }
        .MuiButtonBase-root {
            padding: 0.2rem 0.4rem;
            width: auto;
            min-width: auto !important;
            line-height: normal !important;
            margin-left: auto;
        }
    }
    .value {
        ul {
            padding: 10px;
            list-style: none;
            height: 280px;
            overflow-y: auto;
            border: 1px solid #ddd;
            border-radius: 6px;
            background: #fcfcfc;
            margin: 0.5rem 0;
        }
    }
}
tr.warning-bg {
    background-color: #fff9de !important;
}
.warning-bg.info-iconbox {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: #666;
    background-color: transparent;
    &::before {
        content: "";
        width: 30px;
        height: 15px;
        background-color: #fff9de !important;
        display: block;
        overflow: hidden;
        margin-right: 0.5rem;
        border: 1px solid #ddd;
    }
}

.MuiTableCell-tooltip-container {
    display: flex;
    svg.MuiSvgIcon-root {
        width: 15px;
        color: #999;
        margin-right: 5px;
    }
}
table.MuiTable-root.no-border-table .MuiFormControl-root {
    margin: 0;
}
table.MuiTable-root.no-border-table .text-box-type {
    padding-right: 0 !important;
}
table.MuiTable-root.no-border-table .text-box-type input {
    padding: 0.4rem !important;
}
.tEntry-info {
    display: inline-block;
    .wrap {
        font-size: 0.7rem;
        line-height: 1.2 !important;
        display: flex;
        align-items: center;
        padding: 0.4rem;
        background: #fffbe3;
        border-radius: 4px;
        border: 1px solid #fff6c4;
        svg {
            fill: #e8d568;
            width: 16px;
            margin-right: 0.5rem;
        }
    }
}

.uploads {
    .item {
        label {
            display: flex;
            line-height: normal;
            align-items: center;
            .value {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                .info {
                    font-size: 0.85em;
                    color: #666;
                    display: block;
                }
            }
        }
    }
}
iframe#jsd-widget {
    top: 0;
}
.timesheet-viewpage-error {
    color: red !important;
}
.time-entry-edit-tag {
    margin-left: 12px;
    margin-bottom: 14px;
}
.st-time-entry-edit {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}
.time-entry-edit-reason {
    margin-top: 10px !important;
}
.navmenu-anchor-tag {
    text-decoration: none !important;
    color: inherit !important;
}
.no-radio input {
    display: none !important;
}
.fws-warning {
    font-style: italic;
    margin-left: 14px;
    padding-bottom: 4px;
    margin-top: -12px;
}
.MuiDialogContent-root {
    min-height: 80px !important;
}
.time-entry-edit-tag .MuiSelect-select.MuiSelect-select {
    border-bottom: 1px solid lightgrey;
}

.typeColor-timeoff {
    background-color: #f5e3fa;
    border-color: #e0cae6 !important;
    color: #333;
}
.typeColor-offSite {
    background-color: #fcf0d2;
    border-color: #ebdebe !important;
    color: #333;
}
.typeColor-overtime {
    background-color: #e0fcff;
    border-color: darken(#e0fcff, 10%) !important;
    color: #333;
}
.statusIcon-submitted {
    color: #bb00ff;
}
.statusIcon-approved {
    color: green;
}
.statusIcon-rejected {
    color: red;
}
.statusIcon-cancelled {
    color: #aaa;
}
.clr-grey {
    color: grey !important;
}
.mt-0 {
    margin-top: 0 !important;
}

.rs-status-input {
    margin-bottom: 0 !important;
    .MuiSelect-select {
        padding: 0.2rem 1.3rem 0.2rem 0.4rem;
        font-size: 0.8rem;
    }
    .MuiSelect-iconOutlined {
        right: 0;
    }
}

.tableSubHeader {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    .section-title {
        margin: 0 !important;
    }
    .header-action {
        margin-left: auto;
    }
}

// fixing header style of data table
.MuiToolbar-regular .MuiTypography-h6 {
    font-weight: 700 !important;
    font-size: 1rem !important;
    text-transform: uppercase !important;
    margin-bottom: 0.35em !important;
    line-height: 1.75 !important;
    color: #3f51b5 !important;
}

// Table
.sts-table-wrapper .MuiToolbar-root {
    padding-left: 15px;
}
.sts-table-wrapper.toolbar-hidden .MuiToolbar-root {
    display: none !important;
}
.sts-table-wrapper table thead th {
    vertical-align: top;
    font-weight: 500 !important;
    line-height: 1.5em !important;
    background: #eee;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    border-top: 1px solid #ddd !important;
    border-bottom: 1px solid #ddd !important;
}
.sts-table-wrapper table .MuiIconButton-root {
    padding: 6px !important;
    font-size: 1.2rem !important;
}
.sts-table-wrapper table .MuiIconButton-root .MuiIcon-root,
.sts-table-wrapper table .MuiIconButton-root .MuiSvgIcon-root {
    font-size: 1em !important;
}
.sts-table-title {
    font-weight: 700 !important;
    font-size: 1rem !important;
    text-transform: uppercase !important;
    margin-bottom: 0.35em !important;
    line-height: 1.75 !important;
    color: #3f51b5 !important;
    @include xs {
        display: none;
    }
}
.sts-table-title-sm {
    display: none;
    font-weight: 700 !important;
    font-size: 1rem !important;
    text-transform: uppercase !important;
    margin-bottom: 0.35em !important;
    line-height: 1.75 !important;
    color: #3f51b5 !important;
    @include xs {
        display: block;
    }
}
.fc-more-popover {
  max-height: 75%;
  overflow-y: auto;
}
.MuiDialog-paper{
    @include xs {
        margin: 10px !important;
    }
}
.table-responsive{
    width: 100%;
    overflow-x: auto;
}
.show-mobile-view{
    display: none !important;
    @include xs {
        display: flex !important;
    }
    @media (min-width: 767px) and (max-width:959px){
        display: flex !important;
    }
}
.hide-mobile-view{
    @include xs {
        display: none !important;
    }
    @media (min-width: 767px) and (max-width:959px){
        display: none !important;
    }
}
tr.holiday{
    background-color: #dde1d7 !important;
}
tr.holiday .MuiTableCell-body{
    border-color: #fff !important;
}
.item-link {
    cursor: pointer;
    text-decoration: none;
    color:#0062AD;
}
.item-link:hover {
    color: #3788d8;
}
.userTimeoffList {
    border-color:#ddd;
    td,th{
        padding:0.5rem;
    }
    thead {
        background-color : #eee;
    }
    td {
        min-width:100px;
    }
    td .days{
        display:block;
        color:#46915c;
        font-size:0.9rem;
        margin-bottom:0.4rem;
        line-height:1.1em;
        font-weight: 500;
    }
    td .hours{
        display:block;
        color:#ccab29;
        font-size:0.8rem;
        line-height:1.1em;
        font-weight: 500;
    }
}
.page-details-header {
    display: flex;
    column-gap: 1rem;
    align-items: center;
    background: #eee;
    border-radius: 6px;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0;
    border: 1px solid #dde6ed;

    .title-box {
        .status {
            display: flex;
            column-gap: 0.2rem;

             .staff-type,
             .emp-category,
             .overtime {
                padding: 0.2rem 0.4rem;
                border: 1px solid #ddd;
                background: #bdb384;
                color: #fff;
                font-weight: 500;
                line-height: 1em;
                border-radius: 4px;
                font-size: 0.8rem;
                margin-top: 0.2rem
            }
        }
    }
    .profile-view__basics_default-item {
        min-width:100px;
        flex-direction: column;
        align-items: flex-start;
        margin: 0 !important;
        border-left: 1px solid #bbb;
        padding-left: 1rem;
        label{
            width: 100%;
            color: #666;
            font-weight:600;
            margin-bottom:0.2rem;
            &::after{
                content:'';
            }
        
        }
        .value{
            padding: 0;
            &::before {
                content: "";
                width: 8px;
                height: 8px;
                background: #ddd;
                display: inline-block;
                vertical-align: middle;
                margin-right: 0.2rem;
                border-radius: 50%;
            }
        }
    }
    &.profile-view__basics_sm {
        flex-direction: column;
        align-items: flex-start;
        .profile-view__basics__main{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 1rem;
        }
        .profile-view__basics_default-item{
            flex-direction: row;
            align-items:center;
            margin-bottom:0.3rem !important;
            border:none;
            padding:0;
            label{
                flex: 0 1 40%;
                font-size:0.8rem;
            }
            .value{
                padding: 0 !important;
            }
        }
    }
}

.page-details-header .profile-view__basics_default-item.item-status.status-active .value::before {
    background: #32a881;
}
.page-details-header .profile-view__basics_default-item.item-status.status-inactive .value::before {
    background: red;
}

.page-details-header .profile-view__basics_default-item.item-status .value {
    color: #666;
}
.page-details-header .profile-view__basics_default-item.item-status.status-inactive .value {
    color: red;
}
.page-details-header .profile-view__basics_default-item.item-status.status-active .value {
    color: #32a881;
} 

.page-details-header .profile-view__basics_default-item.item-status .value {
    font-weight: 500;
    color: #000;
    font-size: 0.9rem;
} 

.page-details-content {
    display: flex;
    &.page-details-content_sm{
        display:block;
        .MuiAccordionDetails-root{
            display: block;
            .data-value .value-container{
                display: flex;
               
            }
        }
    }   
    
}
.page-details-content .content-sidebar{
    flex-grow:0;
    width:400px;
    background: #eee;
    position: relative;
    z-index: 0;
    margin-right: 1rem;
    padding: 10px 0 10px 10px;    
    border-top-left-radius:10px;
    border-bottom-left-radius:10px;
    height : 500px;
    overflow-y: auto;
    &::after {
        content: '';
        width: 20px;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        background: #ddd;
        z-index: 1;
        background: linear-gradient(270deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 50%);
    }
}
.timeoff-items{
    display:flex;
    flex-direction: column;
}
.timeoff-items .item {
    padding: 1rem;
    border-bottom: 1px solid #ddd;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    margin: 2px 0;
    z-index: 0;

    &.item-selected {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        position: relative;
        background-color: #fff;
        box-shadow: -7px 5px 10px #ddd;
        z-index: 2;
    }
    &:hover:not(.item-selected) {
        background: rgba(255,255,255,0.8);
        border-color:transparent;
    }
}
.timeoff-items .item .title{
    font-size:1.1em;
    font-weight:600;
}
.timeoff-items .item .value-container{
    display:  flex;
    column-gap: 10px;
    width: 100%;
}
.timeoff-items .item .value-container .value{
    border:1px solid #ddd;
    border-radius:4px;
    display:flex;
    flex-direction:column;
    padding:4px;
    align-items: stretch;
    justify-content:space-between;
    flex: 1;
}
.timeoff-items .item .value-container .value .label{
    font-weight: 600;
    font-size:0.8em;
    text-transform: uppercase;
    margin-bottom: 0.4rem;
    color: #666;
}
.timeoff-items .item .value-container .value .days{
    font-size: 0.9em;
    line-height: 1em;
}
.timeoff-items .item .value-container .value .hours{
    font-size: 0.85em;
    color: #666;
}
.page-details-content .content-main{
    flex: 1;
}
.legend{
    padding: 0.8rem 1rem 0 1rem;
    display: flex;
    column-gap: 1rem;
    align-items: center;
    float: left;
    .day,.hour{
        &::before{
            content: '';
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-right: 0.5rem;
            display: inline-block;
        }
    }
    .day::before{
        background-color: #46915c;
    }
    .hour::before{
        background-color: #ccab29;
    }
}
.worklocationLabel{
    color: $color-primary;
}
table.MuiTable-root.userTimeoffList thead{
   position: sticky; 
   top: 0;
   z-index: 2;
}

.timeoff-summary-sm {
    display: flex;
    font-size:0.8rem;
    column-gap: 0.4rem;
    margin: 0.5rem 0 1rem 0;
}
.timeoff-summary-sm .value{
    border: 1px solid #ddd;
    border-radius: 4px;
    padding:4px;
    flex: 1;
}
.timeoff-summary-sm .value .label{
    font-weight:600;
    display: block;
    margin-bottom:0.4rem;
}
.timeoff-summary-sm .value .hours,
.timeoff-summary-sm .value .days{
    display: block;
}

.fullcalendar-details {
    float: right;
}

.fullcalendar-details .legend {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    column-gap: 0;
}

.fullcalendar-details .legend .icon {
    width: 16px;
    height: 16px;
    display: block;
    margin-right: 0.5rem;
}
.weekDays-selector{
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(7, 30px);
    grid-auto-rows: 30px;
    column-gap: 0.5rem;
}
.weekDays-selector li label{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
    background-color: #eee;
    border-radius: 50%;
    cursor: pointer;
}
.weekDays-selector li input{display: none;}
.weekDays-selector li input:checked + label{
    background-color: #3f51b5;
    color: #fff;
}
.profile-view-aside{
    width: 100%;
    font-size: .875rem;
    line-height: 1.2em;
    color: #1e1f21;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 0.5rem;
    .pb{
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        column-gap: 0.5rem;
        &.pb-highlight{
            background-color: rgba(0,0,0,0.05);
            padding: 0.4rem;
            border-radius: 6px;
        }
    }
    .pb-main{
        flex: 1 0 100%;
        margin-bottom: 1rem;
    }
    .pb-image{
        flex-grow: 0;
        flex-shrink: 1;
        .helper{
            height: 128px;
            width: 128px;
            border-radius: 6px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 100%;
                height: auto;
            }
        }
    }
    .pb-info-sub{
        flex-grow: 1;
    }
    .pb-info{
        flex: 1 0 100%;
        margin-top: 1rem;
    }
    .pbi-item{
        margin: 0.2rem 0;
        flex: 1 0 100%;
        &:first-of-type{
            margin-top: 0;
        }
        &:last-of-type{
            margin-bottom: 0;
        }
    }
    .pbi-status-group{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 0.75rem;
    }
    .pbi-name{
        font-size: 1.4em;
        font-weight: 700;
    }
    .pbi-email,.pbi-phone{
        font-size: 0.9em;
    }
    .pbi-type{
        font-size: 0.9em;
        text-transform: uppercase;
        color: #666;
    }
    .pbi-status{
        
    }
    .pbi-info-list{
        margin: 0;
        padding: 0;
        list-style: none;
        li{
            margin: 0.5rem 0;
            display: flex;
            flex-wrap: wrap;
            .label{
                flex: 0 0 35%;
                min-width: 128px;
                color: #6d6e6f;
                margin-bottom: 0.2rem;
            }
            .value{
                flex: 1;
            }
        }
    }
}
.status-view{
    color: #999;
    font-size: 0.6rem;
    line-height: normal !important;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    border: 1px solid #999;
    padding: 0.1rem 0.2rem;
    border-radius: 4px;
    &.status-active{
        color: #32a881;
        border-color: #32a881;
    }
}
.profile-view-aside .pb.pb-highlight .pbi-info-list li .label{min-width: 60px;}
/*.MuiInputBase-formControl input{
    padding: 0.4rem;
}*/
.steps-boxed-input .MuiGrid-container{
    align-items: center;
}
.steps-boxed-input .MuiSelect-select{
    padding: 0.4rem;
}
.steps-boxed-input fieldset{
    border: none;
}
.st-timeEntry_entry-item .te-task .input-dropdown .MuiInputBase-formControl{
  padding: 0 !important;
}
// .st-timeEntry_entry-item .te-description textarea{
//  height: auto !important;
// }
.bgWhiteContent{
  padding:1rem;
}
.bgWhiteLeft.profile-view{
  padding: 1rem;
  border-right:1px solid #eee;
  height: 100%;
}
.st-timeEntry_date .MuiInputBase-formControl fieldset,
.st-timeEntry_entry-item:not(.with-border) .MuiInputBase-formControl fieldset{
 border:none;
}
.pr-2 { 
    padding-right :20px !important
}
.MuiTab-root .MuiBadge-root .MuiBadge-badge {
    right: -0.5rem;
}  
.appBarMain__toolbar .branding{
    margin: 0 !important;
    .appBarLogo{
        .helper{
            a{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }
        }
    }
}
.navmenu-menu-link .menuItem:hover {
    background-color: rgb(25, 118, 210);
    color: #fff;
}
.dialogTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}